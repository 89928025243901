import axios from "api/axios"
import errorApi, { IContexteApi } from "api/error.api"
import { TrashJourney } from "models"

const DELETE_JOURNEY_URL: string = '/utilisateur/journees/supprimer'

export interface IDeleteJourneyRequest {
    journeyDate: string
}

export interface IDeleteJourneyResponse {
    message: string,
    data: TrashJourney
}

export async function deleteJourneyApi(request: IDeleteJourneyRequest, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: TrashJourney | null }> {
    if (!context || !context.authentification) {
        return { success: false, message: "Veuillez être connecté.", data: null }
    }

    try {
        const axiosResult = await axios.patch(DELETE_JOURNEY_URL, {
            date_journee: request.journeyDate
        }, { headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${context.authentification.accessToken}`
        } })

        const response: IDeleteJourneyResponse = axiosResult.data

        return { success: true, message: response.message, data: TrashJourney.fromJSON(response.data) }
    } catch (error: any) {
        return await errorApi<TrashJourney>(error, deleteJourneyApi, request, context)
    }
}
import axios from "api/axios";
import errorApi, { IContexteApi } from "api/error.api";
import { AxiosResponse } from "axios";

const USER_INFORMATIONS_URL: string = '/utilisateur/informations'

export interface IUserInformationsResponse {
    message: string,
    data: {
        pk_utilisateur_id: string,
        prenom: string,
        nom: string,
        genre: string | null,
        email: string,
        numero_telephone: string | null,
        est_recherchable: boolean,
        autorise_demande_accord_professionnel: boolean,
        langue: string,
        date_creation: string,
        date_verification: string | null,
        date_desactivation: string | null
    }
}

export async function getUserInformationsApi(request: null, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: { userId: string, firstname: string, lastname: string, gender: string | null, email: string, phoneNumber: string | null, isSearchable: boolean, allowProfessionalRequest: boolean, language: string, createdAt: Date, verifiedAt: Date | null, deactivateAt: Date | null } | null }> {
    if (!context || !context.authentification) {
        return { success: false, message: "Veuillez être connecté.", data: null }
    }
    
    try {
        const axiosResult: AxiosResponse = await axios.get(USER_INFORMATIONS_URL, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${context.authentification.accessToken}` } })

        const response: IUserInformationsResponse = axiosResult.data

        return {
            success: true,
            message: response.message,
            data: {
                userId: response.data.pk_utilisateur_id,
                firstname: response.data.prenom,
                lastname: response.data.nom,
                gender: response.data.genre,
                email: response.data.email,
                phoneNumber: response.data.numero_telephone,
                isSearchable: response.data.est_recherchable,
                allowProfessionalRequest: response.data.autorise_demande_accord_professionnel,
                language: response.data.langue,
                createdAt: new Date(response.data.date_creation),
                verifiedAt: response.data.date_verification ? new Date(response.data.date_verification) : null,
                deactivateAt: response.data.date_desactivation ? new Date(response.data.date_desactivation) : null
            }
        }
    } catch (error: any) {
        return await errorApi<{ userId: string, firstname: string, lastname: string, gender: string | null, email: string, phoneNumber: string | null, isSearchable: boolean, allowProfessionalRequest: boolean, language: string, createdAt: Date, verifiedAt: Date | null, deactivateAt: Date | null }>(error, getUserInformationsApi, request, context)
    }
}
import { getJourneyApi } from "api/journey/get-journey.api";
import { getTrashJourneyApi } from "api/journey/get-trash-journey.api";
import { restoreJourneyApi } from "api/journey/restore-journey.api";
import { MainButton, SecondaryButton } from "components/buttons";
import FlatInformation from "components/informations/flat-information.component";
import { CenterPageLoader } from "components/loaders";
import CenterModal from "components/modals/center-modal.component";
import TemplateJourney from "components/templates/template-journey.component"
import useAuthentification from "hooks/useAuthentification.hook";
import useJourney from "hooks/useJourney.hook";
import { Journey, TrashJourney } from "models";
import { NavBarSection } from "models/enums/nav-bar-section.enum"
import moment from "moment";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "sonner";

const DeletedJourneysPage: React.FC = () => {
    const { journeyContext, setJourneyContext } = useJourney();
    const { authentification, setAuthentification } = useAuthentification();

    const navigate: NavigateFunction = useNavigate();

    const [trash, setTrash] = useState<TrashJourney[]>([])
    const [loadingTrash, setLoadingTrash] = useState<boolean>(true)

    const [journeyRestore, setJourneyRestore] = useState<TrashJourney | null>(null)
    const [loadingRestoreJourney, setLoadingRestoreJourney] = useState<boolean>(false)

    const getTrash = async () => {
        setLoadingTrash(true)
        
        const response = await getTrashJourneyApi(null, { authentification: authentification, setAuthentification: setAuthentification, navigation: navigate })

        if (response.success && response.data) {
            setTrash(response.data)
        }

        setLoadingTrash(false)
    }

    const onRestoreJourney = async (journey: TrashJourney) => {
        if (!journeyRestore) return setJourneyRestore(journey)

        setLoadingRestoreJourney(true)

        const response1 = await restoreJourneyApi({ journeyDate: moment(journey.journeyDate).format("YYYY-MM-DD") }, { authentification: authentification, setAuthentification: setAuthentification, navigation: navigate })

        if (!response1.success) return setLoadingRestoreJourney(false)

        const response2 = await getJourneyApi({ journeyDate: moment(journey.journeyDate).format("YYYY-MM-DD") }, { authentification: authentification, setAuthentification: setAuthentification, navigation: navigate })

        if (response2.success) {
            const firstDayOfWeek: Date = new Date()
            firstDayOfWeek.setDate(new Date().getDate() + 1 - (firstDayOfWeek.getDay() === 0 ? 7 : new Date().getDay()))
            firstDayOfWeek.setHours(0, 0, 0, 0)
            const lastDayOfWeek: Date = new Date()
            lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6)
            lastDayOfWeek.setHours(23, 59, 59, 999)

            if (journey.journeyDate >= firstDayOfWeek && journey.journeyDate <= lastDayOfWeek) {
                setJourneyContext(prev => ({ ...prev, journeysWeek: { data: [...prev.journeysWeek.data, response2.data!], loading: false } }))
            }

            if (journey.journeyDate.getMonth() === new Date().getMonth() && journey.journeyDate.getFullYear() === new Date().getFullYear()){
                setJourneyContext(prev => ({ ...prev, journeysMonth: { data: { journeys: [...prev.journeysMonth.data.journeys, response2.data!], pdfFile: prev.journeysMonth.data.pdfFile }, loading: false } }))
            }

            const oneMonthAgo: Date = new Date()
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
            if (journey.journeyDate.getMonth() === oneMonthAgo.getMonth() && journey.journeyDate.getFullYear() === oneMonthAgo.getFullYear()){
                setJourneyContext(prev => ({ ...prev, journeysLastMonth: { data: { journeys: [...prev.journeysLastMonth.data.journeys, response2.data!], pdfFile: prev.journeysLastMonth.data.pdfFile }, loading: false } }))
            }

            if (!journeyContext.pdfPeriods.data.find(pdfPeriod => pdfPeriod.month === journey.journeyDate.getMonth() + 1 && pdfPeriod.year === journey.journeyDate.getFullYear())) {
                setJourneyContext(prev => ({ ...prev, pdfPeriods: { data: [...prev.pdfPeriods.data, { month: journey.journeyDate.getMonth() + 1, year: journey.journeyDate.getFullYear() }], loading: false } }))
            }

            toast.success("La journée a bien été restaurée.")
            setTrash(trash.filter((trashJourney: TrashJourney) => trashJourney.journeyDate.getTime() !== journey.journeyDate.getTime()))
            setJourneyRestore(null)
        }

        setLoadingRestoreJourney(false)
    }

    useEffect(() => {
        getTrash()
    }, [authentification])

    return (
        <TemplateJourney title="Journées supprimées" selectedSection={NavBarSection.JOURNEYS} onClickReturn={() => navigate('/dashboard/journees')} >
            {
                loadingTrash ? <CenterPageLoader content="Récupération de vos journées" /> : (
                    trash.length === 0 ? (
                        <div className="absolute right-1/2 top-1/2 w-full -translate-y-1/2 translate-x-1/2">
                            <p>Aucune journée supprimée.</p>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center gap-5 overflow-auto">
                            {
                                trash.sort((a, b) => a.expectedDeletionDate.getTime() === b.expectedDeletionDate.getTime() ? a.journeyDate.getTime() - b.journeyDate.getTime() : a.expectedDeletionDate.getTime() - b.expectedDeletionDate.getTime()).map((journey: TrashJourney, index: number) => (
                                    <FlatInformation key={index} onClick={() => setJourneyRestore(journey)}>
                                        <div key={index} className="w-full flex flex-row justify-between items-center">
                                            <p>{ moment(journey.journeyDate).format("DD/MM/YYYY") }</p>
                                            <div>
                                                <p className="text-slate-600">Suppression définitive</p>
                                                <p>{ moment(journey.expectedDeletionDate).format("DD/MM/YYYY")  }</p>
                                            </div>
                                        </div>
                                    </FlatInformation>
                                ))
                            }
                            <div className="h-40"></div>
                            {
                                journeyRestore !== null && <CenterModal onCancel={() => setJourneyRestore(null)}>
                                    <div className="flex flex-col gap-4">
                                        <p>Êtes-vous sûr de vouloir restaurer la journée du { moment(journeyRestore.journeyDate).format("DD/MM/YYYY") } ?</p>
                                        <div className="flex justify-around gap-4">
                                            <SecondaryButton label="Non" onClick={() => setJourneyRestore(null)} isDisabled={false} isLoading={false} />
                                            <MainButton label="Oui" onClick={() => onRestoreJourney(journeyRestore)} isDisabled={false} isLoading={loadingRestoreJourney} />
                                        </div>
                                    </div>
                                </CenterModal>
                            }
                        </div>
                    )
                )
            }
        </TemplateJourney>
    )
}

export default DeletedJourneysPage
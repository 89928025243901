import { getJourneyMonthApi } from "api/journey/get-journey-month.api";
import { getJourneyWeekApi } from "api/journey/get-journey-week.api";
import { getPeriodPdfMonthApi } from "api/journey/get-period-pdf-month.api";
import { getRestPeriodsApi } from "api/journey/rest/get-rest-period.api";
import useAuthentification from "hooks/useAuthentification.hook";
import { Journey, PdfPeriod } from "models";
import { PdfJourneyMonthly } from "models/pdf-journey-monthly.api";
import { RestPeriod } from "models/rest-period.model";
import { createContext, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

export interface JourneyProviderProps {
    journeyContext: {
        journeysWeek: {
            data: Journey[];
            loading: boolean;
        };
        journeysMonth: {
            data: { journeys: Journey[], pdfFile: PdfJourneyMonthly | null };
            loading: boolean;
        };
        journeysLastMonth: {
            data: { journeys: Journey[], pdfFile: PdfJourneyMonthly | null };
            loading: boolean;
        };
        restPeriods: {
            data: RestPeriod[] | null;
            loading: boolean;
        };
        pdfPeriods: {
            data: PdfPeriod[];
            loading: boolean;
        };
    }
    setJourneyContext: React.Dispatch<React.SetStateAction<{
        journeysWeek: {
            data: Journey[];
            loading: boolean;
        };
        journeysMonth: {
            data: { journeys: Journey[], pdfFile: PdfJourneyMonthly | null };
            loading: boolean;
        };
        journeysLastMonth: {
            data: { journeys: Journey[], pdfFile: PdfJourneyMonthly | null };
            loading: boolean;
        };
        restPeriods: {
            data: RestPeriod[] | null;
            loading: boolean;
        };
        pdfPeriods: {
            data: PdfPeriod[];
            loading: boolean;
        };
    }>>
}

const JourneyContext = createContext<JourneyProviderProps>({ journeyContext: { journeysWeek: { data: [], loading: true }, journeysMonth: { data: { journeys: [], pdfFile: null }, loading: true }, journeysLastMonth: { data: { journeys: [], pdfFile: null }, loading: true }, restPeriods: { data: null, loading: true }, pdfPeriods: { data: [], loading: true } }, setJourneyContext: () => null });

export const JourneyProvider = ({ children }: any) => {
    const { authentification, setAuthentification } = useAuthentification();
    const navigate: NavigateFunction = useNavigate();

    const [journeyContext, setJourneyContext] = useState<{ journeysWeek: { data: Journey[], loading: boolean }, journeysMonth: { data: { journeys: Journey[], pdfFile: PdfJourneyMonthly | null }, loading: boolean }, journeysLastMonth: { data: { journeys: Journey[], pdfFile: PdfJourneyMonthly | null }, loading: boolean }, restPeriods: { data: RestPeriod[] | null, loading: boolean }, pdfPeriods: { data: PdfPeriod[], loading: boolean } }>({ journeysWeek: { data: [], loading: true }, journeysMonth: { data: { journeys: [], pdfFile: null }, loading: true }, journeysLastMonth: { data: { journeys: [], pdfFile: null }, loading: true }, restPeriods: { data: null, loading: true }, pdfPeriods: { data: [], loading: true } });

    useEffect(() => {
        if (authentification) {
            // Si l'utilisateur n'est pas suspendu, on récupère les données des journées
            if (!authentification.payload.suspension) {
                getJourneyContextData();
            }
            // Sinon, on récupère uniquement les données des PDF 
            else {
                getPdfPeriods();
            }
        }
    }, [authentification])

    const getJourneyContextData = async () => {
        const response1: { status: boolean } = await getJourneysCurrentWeek();
        if (!response1.status) return;

        const response2: { status: boolean } = await getJourneysCurrentMonth();
        if (!response2.status) return;

        const response3: { status: boolean } = await getJourneysLastMonth();
        if (!response3.status) return;

        const response4: { status: boolean } = await getRestPeriods();
        if (!response4.status) return;

        await getPdfPeriods();
    }

    const getJourneysCurrentWeek = async (): Promise<{ status: boolean }> => {
        setJourneyContext(prev => ({ ...prev, journeysWeek: { ...prev.journeysWeek, loading: true } }))

        const response = await getJourneyWeekApi({ navigation: navigate, authentification: authentification, setAuthentification: setAuthentification });

        if (response.success && response.data !== null)
            setJourneyContext(prev => ({ ...prev, journeysWeek: { ...prev.journeysWeek, data: response.data! } }))

        setJourneyContext(prev => ({ ...prev, journeysWeek: { ...prev.journeysWeek, loading: false } }))

        return { status: response.success }
    }

    const getJourneysCurrentMonth = async (): Promise<{ status: boolean }> => {
        const date: Date = new Date();
        const month: number = date.getMonth() + 1;
        const year: number = date.getFullYear();

        setJourneyContext(prev => ({ ...prev, journeysMonth: { ...prev.journeysMonth, loading: true } }))

        const response = await getJourneyMonthApi({ month: month, year: year }, { navigation: navigate, authentification: authentification, setAuthentification: setAuthentification });

        if (response.success && response.data !== null)
            setJourneyContext(prev => ({ ...prev, journeysMonth: { ...prev.journeysMonth, data: { journeys: response.data!.journeys, pdfFile: response.data!.pdfFile } } }))

        setJourneyContext(prev => ({ ...prev, journeysMonth: { ...prev.journeysMonth, loading: false } }))

        return { status: response.success }
    }

    const getJourneysLastMonth = async (): Promise<{ status: boolean }> => {
        const today: Date = new Date();
        const lastMonth: Date = new Date(today.getFullYear(), today.getMonth() - 1, Math.min(today.getDate(), new Date(today.getFullYear(), today.getMonth(), 0).getDate()));

        const month: number = lastMonth.getMonth() + 1;
        const year: number = lastMonth.getFullYear();

        setJourneyContext(prev => ({ ...prev, journeysLastMonth: { ...prev.journeysLastMonth, loading: true } }))

        const response = await getJourneyMonthApi({ month: month, year: year }, { navigation: navigate, authentification: authentification, setAuthentification: setAuthentification });

        if (response.success && response.data !== null)
            setJourneyContext(prev => ({ ...prev, journeysLastMonth: { ...prev.journeysLastMonth, data: { journeys: response.data!.journeys, pdfFile: response.data!.pdfFile } } }))

        setJourneyContext(prev => ({ ...prev, journeysLastMonth: { ...prev.journeysLastMonth, loading: false } }))

        return { status: response.success }
    }

    const getRestPeriods = async (): Promise<{ status: boolean }> => {
        setJourneyContext(prev => ({ ...prev, restPeriods: { ...prev.restPeriods, loading: true } }))

        const response = await getRestPeriodsApi(null, { navigation: navigate, authentification: authentification, setAuthentification: setAuthentification });

        if (response.success)
            setJourneyContext(prev => ({ ...prev, restPeriods: { ...prev.restPeriods, data: response.data } }))

        setJourneyContext(prev => ({ ...prev, restPeriods: { ...prev.restPeriods, loading: false } }))

        return { status: response.success }
    }
    
    const getPdfPeriods = async (): Promise<{ status: boolean }> => {
        setJourneyContext(prev => ({ ...prev, pdfPeriods: { ...prev.pdfPeriods, loading: true } }))

        const response = await getPeriodPdfMonthApi({ navigation: navigate, authentification: authentification, setAuthentification: setAuthentification });

        if (response.success && response.data !== null)
            setJourneyContext(prev => ({ ...prev, pdfPeriods: { ...prev.pdfPeriods, data: response.data! } }))

        setJourneyContext(prev => ({ ...prev, pdfPeriods: { ...prev.pdfPeriods, loading: false } }))

        return { status: response.success }
    }

    return (
        <JourneyContext.Provider value={{ journeyContext: journeyContext, setJourneyContext: setJourneyContext }}>
            {children}
        </JourneyContext.Provider>
    )
}

export default JourneyContext;
import TemplateJourney from "components/templates/template-journey.component";
import useJourney from "hooks/useJourney.hook";
import React, { useEffect, useState } from "react";
import { NavBarSection } from "models/enums/nav-bar-section.enum";
import { CenterPageLoader } from "components/loaders";
import { Journey } from "models";
import moment from "moment";
import { ArrowRight, CalendarPlus, Trash2 } from "lucide-react";
import AddJourneyModal from "components/modals/add-journey.component";
import useAuthentification from "hooks/useAuthentification.hook";
import SuspensionInformation from "components/informations/suspension.component";
import { NavigateFunction, useNavigate } from "react-router-dom";
import FlatInformation from "components/informations/flat-information.component";
import { toast } from "sonner";
import DateInput from "components/inputs/date.component";
import { PdfJourneyMonthly } from "models/pdf-journey-monthly.api";
import PdfFile from "components/informations/pdf-file.component";
import { getPdfMonthApi } from "api/journey/get-pdf-month.api";
import { getAdvanceSearchJourneyApi } from "api/journey/get-advance-search.api";
import { SecondaryButton } from "components/buttons";

const JourneyDashboardPage: React.FC = () => {
    const { journeyContext, setJourneyContext } = useJourney();
    const { authentification, setAuthentification } = useAuthentification();

    const navigate: NavigateFunction = useNavigate();

    const [selectedView, setSelectedView] = useState<'currentMonth' | 'lastMonth' | 'custom'>('currentMonth')
    const [showAddJourneyModal, setShowAddJourneyModal] = useState<boolean>(false)

    const currentDate: Date = new Date();
    const lastMonthDate: Date = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, Math.min(currentDate.getDate(), new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate()));

    const monthsString: string[] = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']

    const onClickAddJourney = () => {
        if (authentification && authentification.payload.desactivation) {
            toast.error("Votre compte est désactivé. Vous ne pouvez pas ajouter de journées.")
            return
        }

        setShowAddJourneyModal(true)
    }

    const downloadJourneyFile = async (month: number, year: number) => {
        const response = await getPdfMonthApi({ month: month, year: year }, { navigation: navigate, authentification: authentification, setAuthentification: setAuthentification })

        if (response.success && response.data) {
            // Créer une URL pour le Blob
            const url = URL.createObjectURL(response.data);

            // Créer un élément de lien (a) et l'ajouter au document
            const link = document.createElement('a');
            link.href = url;
            link.download = "plannify-journee-" + year + "-" + month + ".pdf";

            // Append link to the body, trigger click, and then remove it
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Libérer l'URL après un court délai
            setTimeout(() => URL.revokeObjectURL(url), 100);
        }
    }

    return (
        <TemplateJourney title="Gestion des journées" selectedSection={NavBarSection.JOURNEYS} updateMetaForOverlay={showAddJourneyModal} rightIcon={{ icon: <Trash2 color="white" />, onClick: () => navigate('/dashboard/journees/supprimees') }} >
            {
                authentification && authentification.payload.suspension ? (
                    <SuspensionInformation contactEmail={authentification.payload.suspension.contactEmail} />
                ) : (
                    journeyContext.journeysMonth.loading ?
                    <CenterPageLoader content="Récupération de vos journées" /> :
                    <div className="px-4 w-full">
                        <div className="h-4"></div>
                        <SelectorViewMonth selectedView={selectedView} setSelectedView={setSelectedView} lastMonth={monthsString[lastMonthDate.getMonth()]} />
                        {
                            ['currentMonth', 'lastMonth'].includes(selectedView) ? (
                                <div>
                                    <div className="h-[30px]"></div>
                                    <p className="text-left">{
                                        selectedView === 'currentMonth' ? monthsString[currentDate.getMonth()] + " " + currentDate.getFullYear() : monthsString[lastMonthDate.getMonth()] + " " + lastMonthDate.getFullYear()
                                    }</p>
                                    <div className="h-[10px]"></div>
                                    <JourneyList data={selectedView === 'currentMonth' ? journeyContext.journeysMonth.data : journeyContext.journeysLastMonth.data} navigate={navigate} downloadJourneyFile={downloadJourneyFile} />
                                    <div className="fixed right-5 bottom-[100px] shadow-md shadow-[#566573] flex flex-row items-center gap-2 py-2 px-4 bg-[#232B35] text-white rounded-lg cursor-pointer" onClick={() => onClickAddJourney()}>
                                        <p>Ajouter</p>
                                        <CalendarPlus />
                                    </div>
                                    <div className="h-40"></div>
                                </div>
                            ): (
                                <div>
                                    <AdvancedSearch />
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                showAddJourneyModal ? <AddJourneyModal onCancel={() => setShowAddJourneyModal(false)} navigate={navigate} authentification={authentification} setAuthentification={setAuthentification} journeyContext={journeyContext} setJourneyContext={setJourneyContext} /> : <></>
            }
        </TemplateJourney>
    )
}

interface SelectedViewMonthProps {
    selectedView: 'currentMonth' | 'lastMonth' | 'custom',
    setSelectedView: (view: 'currentMonth' | 'lastMonth' | 'custom') => void,
    lastMonth: string
}

const SelectorViewMonth: React.FC<SelectedViewMonthProps> = ({ selectedView, setSelectedView, lastMonth }) => {

    return (
        <div className="flex flex-row justify-between rounded-[6px] w-full bg-[#D9D9D9]">
            <p className={"w-full py-1.5 rounded-[6px] text-center cursor-pointer" + (selectedView === 'currentMonth' ? ' bg-[#232B35] text-white' : '')} onClick={() => setSelectedView('currentMonth')}>Ce mois</p>
            <p className={"w-full py-1.5 rounded-[6px] text-center cursor-pointer" + (selectedView === 'lastMonth' ? ' bg-[#232B35] text-white' : '')} onClick={() => setSelectedView('lastMonth')}>{lastMonth}</p>
            <p className={"w-full py-1.5 rounded-[6px] text-center cursor-pointer" + (selectedView === 'custom' ? ' bg-[#232B35] text-white' : '')} onClick={() => setSelectedView('custom')}>Personnalisé</p>
        </div>
    )
}

const AdvancedSearch: React.FC = () => {
    const { authentification, setAuthentification } = useAuthentification();
    const navigate: NavigateFunction = useNavigate();

    const [inputs, setInputs] = useState<{ from: string, to: string }>({ from: '', to: '' })

    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<{ journeys: Journey[], pdfFile: PdfJourneyMonthly | null }[]>([])
    const [pagination, setPagination] = useState<{ page: number, limit: number, total: number } | null>(null)

    const mois: string[] = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']

    const fetchData = async (fromDate: string, endDate: string, page: number) => {
        if (fromDate === '' || endDate === '') {
            return
        }

        setLoading(true)
        const response = await getAdvanceSearchJourneyApi({ startDate: new Date(fromDate), endDate: new Date(endDate), page: page }, { navigation: navigate, authentification: authentification, setAuthentification: setAuthentification });
        setLoading(false)

        if (response.success && response.data !== null) {
            setPagination(response.data.pagination)
            setData(response.data.research)
        }
    }

    const downloadJourneyFile = async (month: number, year: number, setLoading: Function) => {
        setLoading(true)
        const response = await getPdfMonthApi({ month: month, year: year }, { navigation: navigate, authentification: authentification, setAuthentification: setAuthentification })
        setLoading(false)

        if (response.success && response.data) {
            // Créer une URL pour le Blob
            const url = URL.createObjectURL(response.data);

            // Créer un élément de lien (a) et l'ajouter au document
            const link = document.createElement('a');
            link.href = url;
            link.download = "plannify-journee-" + year + "-" + month + ".pdf";

            // Append link to the body, trigger click, and then remove it
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Libérer l'URL après un court délai
            setTimeout(() => URL.revokeObjectURL(url), 100);
        }
    }

    useEffect(() => {
        fetchData(inputs.from, inputs.to, page);
    }, [inputs, page])

    return (
        <div>
            <div className="flex justify-around my-4">
                <div className="flex flex-col items-start">
                    <label className="ml-1" htmlFor="from">De</label>
                    <DateInput id="from" value={inputs.from} isError={false} inputType="interactive" onChange={(e) => setInputs(prev => ({ to: prev.to, from: e.target.value }))} />
                </div>
                <div className="flex flex-col items-start">
                    <label className="ml-1" htmlFor="to">À</label>
                    <DateInput id="to" value={inputs.to} isError={false} inputType="interactive" onChange={(e) => setInputs(prev => ({ to: e.target.value, from: prev.from }))} />
                </div>
            </div>
            {
                pagination && page > 1 && (
                    <>
                        <SecondaryButton label="Page précédente" isDisabled={false} isLoading={loading} onClick={() => setPage(prev => prev - 1)} />
                        <div className="h-4"></div>
                    </>
                )
            }
            {
                inputs.from === '' || inputs.to === '' ? <p className="text-center text-slate-600">Les données seront chargées dès que les champs de saisies ne seront plus vides.</p> : (
                    loading ? <CenterPageLoader content="Recherche en cours" /> : (
                        data.length === 0 ? (
                            <div className="absolute right-1/2 top-1/2 w-full -translate-y-1/2 translate-x-1/2">
                                <p>Aucune journée trouvée.</p>
                            </div>
                        ) : (
                            data.map((journeyData, index) => (
                                <div key={index}>
                                    <p className="text-left ml-4 mb-2">{ mois[journeyData.pdfFile ? journeyData.pdfFile.month - 1 : journeyData.journeys[0].journeyDate.getMonth()] } {journeyData.pdfFile ? journeyData.pdfFile.year : journeyData.journeys[0].journeyDate.getFullYear()}</p>
                                    {
                                        journeyData.pdfFile !== null ? (
                                            <div className="flex justify-center">
                                                <PdfFile file={journeyData.pdfFile} onClick={() => downloadJourneyFile(journeyData.pdfFile!.month, journeyData.pdfFile!.year, () => {})} />
                                            </div>
                                        ) : (
                                            journeyData.journeys.length !== 0 && <JourneyList data={journeyData} navigate={navigate} />
                                        )
                                    }
                                    {
                                        data.length - 1 !== index && <hr className="my-4 border-1 border-slate-600" />
                                    }
                                </div>
                            ))
                        )
                    )
                )
            }
            {
                pagination && page * pagination.limit < pagination.total && (
                    <>
                        <div className="h-4"></div>
                        <SecondaryButton label="Page suivante" isDisabled={false} isLoading={loading} onClick={() => setPage(prev => prev + 1)} />
                    </>
                )
            }
            <div className="h-40"></div>
        </div>
    )
}

interface JourneyListProps {
    data: { journeys: Journey[], pdfFile: PdfJourneyMonthly | null },
    navigate: NavigateFunction,
    downloadJourneyFile?: (month: number, year: number) => void
}

const JourneyList: React.FC<JourneyListProps> = ({ data, navigate, downloadJourneyFile }) => {
    return (
        data.pdfFile !== null ? (
            <div className="absolute right-1/2 top-1/2 w-full -translate-y-1/2 translate-x-1/2">
                <div className="flex justify-center">
                    <PdfFile file={data.pdfFile} onClick={downloadJourneyFile ? () => downloadJourneyFile(data.pdfFile!.month, data.pdfFile!.year) : undefined} />
                </div>
            </div>
        ) : (
            <>
                {
                    data.journeys.length === 0 ? (
                        <div className="absolute right-1/2 top-1/2 w-full -translate-y-1/2 translate-x-1/2">
                            <p>Aucune journée enregistrée.</p>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center gap-5 overflow-auto">
                            {
                                data.journeys.sort((a, b) => a.journeyDate.getTime() - b.journeyDate.getTime()).map((journey: Journey, index: number) => (
                                    <FlatInformation key={index} onClick={() => navigate('/dashboard/modifier-journee/' + moment(journey.journeyDate).format("YYYY-MM-DD"), { state: { journey: journey } })}>
                                        <div key={index} className="w-full flex flex-row justify-between items-center">
                                            <p>{moment(journey.journeyDate).format("DD/MM/YYYY")}</p>
                                            <div>
                                                <p className="flex items-center gap-1">{moment(journey.startHour).format("HH:mm:ss")}<ArrowRight size={18} color="rgb(71 85 105)" />{journey.endHour ? moment(journey.endHour).format("HH:mm:ss") : 'en cours'}</p>
                                                <p><span className="text-slate-600">Coupure : </span>{moment(journey.restPeriod).format("HH:mm:ss")}</p>
                                            </div>
                                        </div>
                                    </FlatInformation>
                                ))
                            }
                        </div>
                    )
                }
            </>
        )
    )
}

export default JourneyDashboardPage
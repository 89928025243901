export class TrashJourney {
    journeyDate: Date;
    creationDate: Date;
    expectedDeletionDate: Date;

    public constructor(journeyDate: Date, creationDate: Date, expectedDeletionDate: Date) {
        this.journeyDate = journeyDate;
        this.creationDate = creationDate;
        this.expectedDeletionDate = expectedDeletionDate;
    }

    public static fromJSON(json: any): TrashJourney {
        return new TrashJourney(
            new Date(json['date_journee']),
            new Date(json['date_ajout']),
            new Date(json['date_suppression_prevue'])
        );
    }
}
import axios from "api/axios";
import errorApi, { IContexteApi } from "api/error.api";
import { AxiosResponse } from "axios";

const STATUS_DEACTIVATE_MAIL_PREFERENCE: string = '/utilisateur/token/etat-preference-mail'

export interface IStatusDeactivateMailPreferenceRequest {
    token: string;
    typeMailId: number;
}

export interface IStatusDeactivateMailPreferenceResponse {
    message: string,
    data: {
        est_actif: boolean,
        est_modifiable: boolean
    } | null
}

export async function statusDeactivateMailPreferenceApi(request: IStatusDeactivateMailPreferenceRequest, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: { isActive: boolean, isModifiable: boolean } | null }> {
    try {
        const axiosResult: AxiosResponse = await axios.get(STATUS_DEACTIVATE_MAIL_PREFERENCE + '/' + request.token + '/' + request.typeMailId, { headers: { 'Content-Type': 'application/json' } });

        const response: IStatusDeactivateMailPreferenceResponse = axiosResult.data

        return { success: true, message: response.message, data: response.data ? { isActive: response.data.est_actif, isModifiable: response.data.est_modifiable } : null };
    } catch (error: any) {
        return await errorApi<{ isActive: boolean, isModifiable: boolean }>(error, statusDeactivateMailPreferenceApi, request, context)
    }
}
import TemplateJourney from "components/templates/template-journey.component";
import useJourney from "hooks/useJourney.hook";
import { Journey } from "models";
import React, { useEffect, useState } from "react";
import { NavBarSection } from "models/enums/nav-bar-section.enum";
import { MainButton, SecondaryButton } from "components/buttons";
import { CenterPageLoader } from "components/loaders";
import useAuthentification from "hooks/useAuthentification.hook";
import SuspensionInformation from "components/informations/suspension.component";
import { NavigateFunction, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "sonner";
import { addJourneyApi } from "api/journey/add-journey.api";
import { updateJourneyApi } from "api/journey/update-journey.api";
import { RestPeriod } from "models/rest-period.model";
import CenterModal from "components/modals/center-modal.component";
import TimeInput from "components/inputs/time.component";

const WeekDashboardPage: React.FC = () => {
    const { authentification, setAuthentification } = useAuthentification();
    const { journeyContext, setJourneyContext } = useJourney();

    const navigate: NavigateFunction = useNavigate();

    const [updateJourney, setUpdateJourney] = useState<boolean>(false);
    const [stateJourney, setStateJourney] = useState<Journey | undefined>(undefined);

    const [cancelEndJourneyConfirmation, setCancelEndJourneyConfirmation] = useState<boolean>(false);

    const [restPeriodWithoutAutomatisationInput, setRestPeriodWithoutAutomatisationInput] = useState<string>('00:00');
    const [askRestPeriod, setAskRestPeriod] = useState<boolean>(false);

    useEffect(() => {
        setStateJourney(journeyContext.journeysWeek.data.find(j => {
            const start = moment(j.startHour);
            const now = moment();
            const hoursDiff = now.diff(start, 'hours');
            
            return (hoursDiff < 18 && hoursDiff >= 0) || moment(j.journeyDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
        }));
    }, [journeyContext.journeysWeek.data]);

    const startJourney = async () => {
        if (authentification && authentification.payload.desactivation) {
            toast.error("Votre compte est désactivé. Vous ne pouvez pas commencer une journée.")
            return
        }

        if (stateJourney) {
            toast.error("Vous avez déjà commencé votre journée.");
            return;
        }

        const currentMoment: Date = new Date();

        setUpdateJourney(true);
        const request = await addJourneyApi({ journeyDate: currentMoment, startHour: currentMoment, endHour: null, restPeriod: new Date(moment(currentMoment).format('YYYY-MM-DD') + ' 00:00:00'), overnightRest: false }, { authentification: authentification, setAuthentification: setAuthentification, navigation: navigate });
        setUpdateJourney(false);

        if (request.success && request.data !== null) {
            setJourneyContext(prev => ({
                ...prev,
                journeysWeek: {
                    ...prev.journeysWeek,
                    data: [
                        ...prev.journeysWeek.data, request.data!
                    ]
                },
                journeysMonth: {
                    ...prev.journeysMonth,
                    data: {
                        journeys: [
                            ...prev.journeysMonth.data.journeys, request.data!
                        ],
                        pdfFile: prev.journeysMonth.data.pdfFile
                    }
                },
                pdfPeriods: {
                    ...prev.pdfPeriods,
                    data: [
                        ...prev.pdfPeriods.data.filter(
                            pdf => !(pdf.month === request.data!.journeyDate.getMonth() + 1 && pdf.year === request.data!.journeyDate.getFullYear())
                        ), {
                            month: request.data!.journeyDate.getMonth() + 1,
                            year: request.data!.journeyDate.getFullYear()
                        }
                    ]
                }
            }));

            setStateJourney(request.data)
            toast.success("La journée a bien été créée.");
        }
    }

    const endJourney = async () => {
        if (!stateJourney) {
            toast.error("Vous n'avez pas commencé de journée.");
            return;
        }

        const currentMoment: Date = new Date();
        let restPeriodAuto: RestPeriod | undefined = undefined;

        if (journeyContext.restPeriods.data !== null) {
            restPeriodAuto = Journey.getRestPeriod(stateJourney.startHour, currentMoment, journeyContext.restPeriods.data);
        }
        

        setUpdateJourney(true);
        const request = await updateJourneyApi({ journeyDate: stateJourney.journeyDate, startHour: stateJourney.startHour, endHour: currentMoment, restPeriod: restPeriodAuto ? restPeriodAuto.restTime : new Date(moment(currentMoment).format('YYYY-MM-DD') + ' ' + restPeriodWithoutAutomatisationInput + ':00'), overnightRest: false }, { authentification: authentification, setAuthentification: setAuthentification, navigation: navigate });
        setUpdateJourney(false);

        if (request.success && request.data !== null) {
            setJourneyContext(prev => ({...prev, journeysWeek: {...prev.journeysWeek, data: [...prev.journeysWeek.data.filter(j => moment(j.journeyDate).format('YYYY-MM-DD') !== moment(request.data!.journeyDate).format('YYYY-MM-DD')), request.data!] }, journeysMonth: {...prev.journeysMonth, data: { journeys: [...prev.journeysMonth.data.journeys.filter(j => moment(j.journeyDate).format('YYYY-MM-DD') !== moment(request.data!.journeyDate).format('YYYY-MM-DD')), request.data!], pdfFile: prev.journeysMonth.data.pdfFile } }}));
            setStateJourney(request.data);
            setAskRestPeriod(false);
            setRestPeriodWithoutAutomatisationInput('00:00');
            toast.success("La journée a bien été terminée.");
        }
    }

    const cancelEndJourney = async () => {
        if (!stateJourney) {
            toast.error("Vous n'avez pas commencé de journée.");
            return;
        }

        if (!cancelEndJourneyConfirmation) {
            setCancelEndJourneyConfirmation(true);
            return;
        }

        setUpdateJourney(true);
        const request = await updateJourneyApi({ journeyDate: stateJourney.journeyDate, startHour: stateJourney.startHour, endHour: null, restPeriod: stateJourney.restPeriod, overnightRest: stateJourney.overnightRest }, { authentification: authentification, setAuthentification: setAuthentification, navigation: navigate });
        setUpdateJourney(false);

        if (request.success && request.data !== null) {
            setJourneyContext(prev => ({...prev, journeysWeek: {...prev.journeysWeek, data: [...prev.journeysWeek.data.filter(j => moment(j.journeyDate).format('YYYY-MM-DD') !== moment(request.data!.journeyDate).format('YYYY-MM-DD')), request.data!] }, journeysMonth: {...prev.journeysMonth, data: { journeys: [...prev.journeysMonth.data.journeys.filter(j => moment(j.journeyDate).format('YYYY-MM-DD') !== moment(request.data!.journeyDate).format('YYYY-MM-DD')), request.data!], pdfFile: prev.journeysMonth.data.pdfFile } }}));
            setStateJourney(request.data)
            toast.success("La fin de journée a bien été annulée.");
        }

        setCancelEndJourneyConfirmation(false);
    }

    const updateOvernightRest = async (value: boolean) => {
        if (!stateJourney) {
            toast.error("Vous n'avez pas commencé de journée.");
            return;
        }

        setUpdateJourney(true);
        const request = await updateJourneyApi({ journeyDate: stateJourney.journeyDate, startHour: stateJourney.startHour, endHour: stateJourney.endHour, restPeriod: stateJourney.restPeriod, overnightRest: value }, { authentification: authentification, setAuthentification: setAuthentification, navigation: navigate });
        setUpdateJourney(false);

        if (request.success && request.data !== null) {
            setJourneyContext(prev => ({...prev, journeysWeek: {...prev.journeysWeek, data: [...prev.journeysWeek.data.filter(j => moment(j.journeyDate).format('YYYY-MM-DD') !== moment(request.data!.journeyDate).format('YYYY-MM-DD')), request.data!] }, journeysMonth: {...prev.journeysMonth, data: { journeys: [...prev.journeysMonth.data.journeys.filter(j => moment(j.journeyDate).format('YYYY-MM-DD') !== moment(request.data!.journeyDate).format('YYYY-MM-DD')), request.data!], pdfFile: prev.journeysMonth.data.pdfFile } }}));
            setStateJourney(request.data)
            toast.success("Le découchage a bien été modifié.");
        }
    }
    
    return (
        <TemplateJourney title="Gestion des horaires" selectedSection={NavBarSection.HOME}>
            {
                authentification && authentification.payload.suspension ? (
                    <SuspensionInformation contactEmail={authentification.payload.suspension.contactEmail} />
                ) : (
                    journeyContext.journeysWeek.loading || journeyContext.journeysMonth.loading ?
                    <CenterPageLoader content="Récupération de vos journées" /> :
                    <>
                        <div className="h-4"></div>
                        <div className="py-2 px-4 w-full">
                            <WeekTableJourneyInformations weekJourneys={journeyContext.journeysWeek.data} monthJourneys={journeyContext.journeysMonth.data.journeys} />
                            {
                                stateJourney && stateJourney.endHour && (
                                    <>
                                        <div className="h-2"></div>
                                        <p className="text-base text-left cursor-pointer underline text-[#232B35]" onClick={() => updateJourney ? {} : cancelEndJourney()} >Annuler la fin de la journée</p>
                                    </>
                                )
                            }
                        </div>
                        <div className="h-16"></div>
                        <div>
                            <div className="w-[70%] inline-block">
                                {
                                    stateJourney ? (
                                        stateJourney.endHour ? (
                                            stateJourney.overnightRest ? (
                                                <MainButton label="Désactiver le découchage" fullWidth={true} onClick={() => updateOvernightRest(false)} isDisabled={updateJourney} isLoading={updateJourney} />
                                            ) : (
                                                <MainButton label="Activer le découchage" fullWidth={true} onClick={() => updateOvernightRest(true)} isDisabled={updateJourney} isLoading={updateJourney} />
                                            )
                                        ) : (
                                            <MainButton label="Terminer la journée" fullWidth={true} onClick={() => journeyContext.restPeriods.data ? endJourney() : setAskRestPeriod(true)} isDisabled={updateJourney} isLoading={updateJourney} />
                                        )
                                    ) : (
                                        <MainButton label="Commencer la journée" fullWidth={true} onClick={() => startJourney()} isDisabled={updateJourney} isLoading={updateJourney} />
                                    )
                                }
                            </div>
                            <div className="h-[40px]"></div>
                            <div className="w-[70%] inline-block">
                                <SecondaryButton label="Coupures automatisées" fullWidth={true} onClick={() => navigate('/dashboard/coupures')} isDisabled={false} isLoading={false} />
                            </div>
                        </div>
                        {
                            cancelEndJourneyConfirmation && <CenterModal onCancel={() => setCancelEndJourneyConfirmation(false)}>
                                <div className="flex flex-col gap-4">
                                    <p>Êtes-vous sûr de vouloir annuler la fin de votre journée ?</p>
                                    <div className="flex justify-around gap-4">
                                        <SecondaryButton label="Non" onClick={() => setCancelEndJourneyConfirmation(false)} isDisabled={false} isLoading={false} />
                                        <MainButton label="Oui" onClick={() => cancelEndJourney()} isDisabled={false} isLoading={updateJourney} />
                                    </div>
                                </div>
                            </CenterModal>
                        }
                        {
                            askRestPeriod && <CenterModal onCancel={() => setAskRestPeriod(false)}>
                                <div className="flex flex-col gap-4">
                                    <p>Veuillez saisir le temps de votre coupure :</p>
                                    <div className="mt-4 flex flex-col gap-6">
                                        <div>
                                            <TimeInput value={restPeriodWithoutAutomatisationInput} isError={false} inputType="interactive" isDisabled={false} onChange={(e: any) => setRestPeriodWithoutAutomatisationInput(e.target.value)} />
                                        </div>
                                        <div className="flex justify-around gap-4">
                                            <SecondaryButton label="Annuler" onClick={() => setAskRestPeriod(false)} isDisabled={false} isLoading={false} />
                                            <MainButton label="Valider" onClick={() => endJourney()} isDisabled={false} isLoading={false} />
                                        </div>
                                    </div>
                                </div>
                            </CenterModal>
                        }
                    </>
                )
            }
        </TemplateJourney>
    )
}

const WeekTableJourneyInformations: React.FC<{ weekJourneys: Journey[], monthJourneys: Journey[] }> = ({ weekJourneys, monthJourneys }) => {
    const totalMonthHours: number = monthJourneys.reduce((acc, journey: Journey) => acc + journey.calculWorkTimeInSeconds(), 0);

    const weekDays: { day: number, name: string }[] = [{ day: 1, name: 'Lundi' }, { day: 2, name: 'Mardi' }, { day: 3, name: 'Mercredi' }, { day: 4, name: 'Jeudi' }, { day: 5, name: 'Vendredi' }, { day: 6, name: 'Samedi' }, { day: 0, name: 'Dimanche' }];
    const months: string[] = ['de janvier', 'de février', 'de mars', "d'avril", 'de mai', 'de juin', 'de juillet', "d'août", 'de septembre', "d'octobre", 'de novembre', 'de décembre'];

    return (
        <div className="bg-white px-4 py-2 border border-[#DADADA] rounded-lg">
            <table className="w-full text-left text-base">
                <tbody>
                    <tr>
                        <th>Jour</th>
                        <th>Début</th>
                        <th>Fin</th>
                        <th>Coupure</th>
                    </tr>
                    <tr className="h-[6px]"></tr>
                    {
                        weekDays.map((weekDay: { day: number, name: string }, index: number) => {
                            const journey = weekJourneys.find(j => j.journeyDate.getDay() === weekDay.day);
                            if ([0, 6].includes(weekDay.day) && !journey) return <tr key={index}></tr>

                            return (
                                <tr key={index}>
                                    <td>{weekDay.name}</td>
                                    <td>{journey ? moment(journey.startHour).format("HH:mm:ss") : ''}</td>
                                    <td>{journey && journey.endHour ? moment(journey.endHour).format('HH:mm:ss') : ''}</td>
                                    <td>{journey && journey.endHour ? moment(journey.restPeriod).format("HH:mm:ss") : ''}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <hr className="my-3" />
            <div className="flex justify-between text-base">
                <p>Heures du mois {months[new Date().getMonth()]}</p>
                <p>{ Journey.formatWorkTimeToString(totalMonthHours, false, { showHour: true, showMinute: true, showSecond: false }) }</p>
            </div>
        </div>
    )
}

export default WeekDashboardPage
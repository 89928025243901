export class Update {
    version: string;
    description: string;
    realisationDate: Date | null;

    public constructor(version: string, description: string, realisationDate: Date | null) {
        this.version = version;
        this.description = description;
        this.realisationDate = realisationDate;
    }

    public static fromJSON(json: any): Update {
        return new Update(
            json['version'],
            json['description'],
            json['date_realisation_obligatoire'] ? new Date(json['date_realisation_obligatoire']) : null
        );
    }
}
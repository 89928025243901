import axios from "api/axios";
import errorApi, { IContexteApi } from "api/error.api";
import { AxiosResponse } from "axios";

const DEACTIVATE_MAIL_PREFERENCE_BY_TOKEN: string = '/utilisateur/token/desactivation-preference-mail'

export interface IDeactivateMailPreferenceByTokenRequest {
    token: string;
    typeMailId: number;
}

export interface IDeactivateMailPreferenceByTokenResponse {
    message: string,
    data: {
        est_actif: boolean,
        est_modifiable: boolean
    } | null
}

export async function deactivateMailPreferenceByTokenApi(request: IDeactivateMailPreferenceByTokenRequest, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: null }> {
    try {
        const axiosResult: AxiosResponse = await axios.patch(DEACTIVATE_MAIL_PREFERENCE_BY_TOKEN, {
            token: request.token,
            preference_mail_id: request.typeMailId
        }, { headers: { 'Content-Type': 'application/json' } });

        const response: IDeactivateMailPreferenceByTokenResponse = axiosResult.data;

        return { success: true, message: response.message, data: null };
    } catch (error: any) {
        return await errorApi<null>(error, deactivateMailPreferenceByTokenApi, request, context)
    }
}
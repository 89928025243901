import { MainButton } from "components/buttons";
import TemplateJourney from "components/templates/template-journey.component";
import useAuthentification from "hooks/useAuthentification.hook";
import { Suspension } from "models";
import moment from "moment";
import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { NavBarSection } from "models/enums/nav-bar-section.enum";
import FlatInformation from "components/informations/flat-information.component";
import { BookUser, LifeBuoy, Mail } from "lucide-react";

const ProfileDashboardPage: React.FC = () => {
    const { authentification, setAuthentification } = useAuthentification();
    const navigate: NavigateFunction = useNavigate();

    const deconnexion = () => {
        if (authentification) {
            setAuthentification(null)
            navigate('/connexion')
        }
    }

    return (
        !authentification ? <></> :
        <TemplateJourney title="Mon compte" selectedSection={NavBarSection.PROFILE}>
            <div className="text-left py-4 px-6">
                <p className="p-0 m-0">
                    {
                        authentification.payload.utilisateur.gender ? (
                            authentification.payload.utilisateur.gender + ". " + authentification.payload.utilisateur.firstname + " " + authentification.payload.utilisateur.lastname.toUpperCase()
                        ) : (
                            authentification.payload.utilisateur.firstname + " " + authentification.payload.utilisateur.lastname.toUpperCase()
                        )
                    }
                </p>
                <p className="text-slate-600 p-0 m-0">{ authentification.payload.utilisateur.email }</p>
            </div>
            {/* <p>Votre token expire à { moment(new Date(authentification.payload.exp * 1000)).format("HH:mm:ss") } mais sa mise-à-jour se fera à { moment(new Date((authentification.payload.exp * 1000) - 1000 * 10)).format("HH:mm:ss") } !</p> */}
            {
                authentification.payload.suspension ? <SuspensionInformation suspension={authentification.payload.suspension.donnees} navigate={navigate} /> : <></>
            }
            {
                authentification.payload.desactivation ? <DesactivationInformation desactivationDate={authentification.payload.desactivation} /> : <></>
            }
            <div className="w-full flex flex-col items-center gap-6 my-4">
                <FlatInformation onClick={() => navigate('/parametres/informations')}>
                    <div className="w-full flex flex-row items-center gap-2">
                        <div className="p-2">
                            <BookUser />
                        </div>
                        <p>Mes informations</p>
                    </div>
                </FlatInformation>
                <FlatInformation onClick={() => navigate('/parametres/mails')}>
                    <div className="w-full flex flex-row items-center gap-2">
                        <div className="p-2">
                            <Mail />
                        </div>
                        <p>Gérer les mails</p>
                    </div>
                </FlatInformation>
                <FlatInformation onClick={() => navigate('/parametres/assistance')}>
                    <div className="w-full flex flex-row items-center gap-2">
                        <div className="p-2">
                            <LifeBuoy />
                        </div>
                        <p>Besoin d'assistance ?</p>
                    </div>
                </FlatInformation>
            </div>
            <div className="h-10"></div>
            <div className="flex justify-center">
                <MainButton label="Déconnexion" onClick={deconnexion} isDisabled={false} isLoading={false} />
            </div>
            <div className="h-10"></div>
            <div>
                <p>Version { process.env.REACT_APP_VERSION }</p>
            </div>
            <div className="h-40"></div>
        </TemplateJourney>
    )
}

const SuspensionInformation: React.FC<{ suspension: Suspension, navigate: NavigateFunction }> = ({ suspension, navigate }) => {

    return (
        <div className="flex justify-center text-base my-6">
            <div className="w-[90%] bg-white rounded-lg border border-[#FF0000] text-left py-1 px-2">
                <p className="text-[#FF0000]">Suspension</p>
                <div className="h-[10px]"></div>
                <p>Votre compte est en procédure de suspension avec un accès restreint.</p>
                <div className="h-[10px]"></div>
                <p className="underline" onClick={() => navigate('/suspension', { state: { suspension: suspension } })}>Plus d'informations</p>
            </div>
        </div>
    )
}

const DesactivationInformation: React.FC<{ desactivationDate: string }> = ({ desactivationDate }) => {

    return (
        <div className="flex justify-center text-base my-6">
            <div className="w-[90%] bg-white rounded-lg border border-[#FF0000] text-left py-1 px-2">
                <p className="text-[#FF0000]">Désactivation</p>
                <div className="h-[10px]"></div>
                <p>Votre compte est en procédure de désactivation. Toutes vos données seront automatiquement supprimées dès que la date de désactivation sera atteinte.</p>
                <div className="h-[10px]"></div>
                <p>Celle-ci est prévue pour le <strong>{moment(desactivationDate).format("DD/MM/YYYY")}</strong>.</p>
            </div>
        </div>
    )
}

export default ProfileDashboardPage
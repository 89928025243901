import { addJourneyApi } from "api/journey/add-journey.api"
import { MainButton, SecondaryButton } from "components/buttons"
import { Selector } from "components/inputs"
import DateInput from "components/inputs/date.component"
import TimeInput from "components/inputs/time.component"
import { RestPeriod } from "models/rest-period.model"
import moment from "moment"
import { InputSwitch } from "primereact/inputswitch"
import { JourneyProviderProps } from "providers/journey.provider"
import { useEffect, useState } from "react"
import { NavigateFunction } from "react-router-dom"
import { toast } from "sonner"
import { addJourneyReplaceApi } from "api/journey/add-journey-replace.api"
import { ArrowRightLeft, Eraser } from "lucide-react"
import { Journey } from "models"

interface IAddJourneyModalProps {
    onCancel: () => void,
    navigate: NavigateFunction,
    authentification: any,
    setAuthentification: any,
    journeyContext: JourneyProviderProps['journeyContext'],
    setJourneyContext: JourneyProviderProps['setJourneyContext']
}

const AddJourneyModal: React.FC<IAddJourneyModalProps> = ({ onCancel, navigate, authentification, setAuthentification, journeyContext, setJourneyContext }) => {
    const restPeriods: RestPeriod[] | null = journeyContext.restPeriods.data
    const months: string[] = ['de janvier', 'de février', 'de mars', "d'avril", 'de mai', 'de juin', 'de juillet', "d'août", 'de septembre', "d'octobre", 'de novembre', 'de décembre'];

    const [journeyInputs, setJourneyInputs] = useState<{ date: string, startHour: string, endHour: string, rest: string, overnightRest: boolean }>({ date: moment().format("YYYY-MM-DD"), startHour: '', endHour: '', rest: restPeriods !== null && restPeriods.length > 0 ? 'automatique' : '00:00:00', overnightRest: false })
    const [journeyErrors, setJourneyErrors] = useState<{ date: boolean, startHour: boolean, endHour: boolean, rest: boolean }>({ date: false, startHour: false, endHour: false, rest: false })

    const [loading, setLoading] = useState<boolean>(false)
    const [typeModalWarning, setTypeModalWarning] = useState<'duplicateJourney' | 'existingPdfFile' | undefined>(undefined)

    const [useRestPeriodsContext, setUseRestPeriodsContext] = useState<boolean>(true);

    const isDateValid = (date: string): boolean => {
        return /^(0[1-9_]|1[0-9_]|2[0-9_]|3[01_])\/(0[1-9_]|1[0-2_]|__)\/([1-2_][0-9_][0-9_][0-9_])$/.test(date) || /^([12][0-9][0-9][0-9])-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[01])$/.test(date) || date.trim().length === 0
    }

    const isHourValid = (hour: string): boolean => {
        return /^([01_][0-9_]|2[0-3_]):([0-5_][0-9_])(:([0-5_][0-9_]))?$/.test(hour) || hour.trim().length === 0
    }

    const onAddJourney = async (dateText: string, startHour: string, endHour: string, rest: string, overnightRest: boolean) => {
        if (journeyErrors.date || journeyErrors.startHour || journeyErrors.endHour || journeyErrors.rest) return

        let stringDate: string = ""
        let journeyDate: Date = new Date()

        const dateParts = dateText.trim().split('/')
        if (dateParts.length === 3) {
            stringDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
            journeyDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))
        } else if (dateText.trim().split('-').length === 3) {
            stringDate = dateText.trim()
            journeyDate = new Date(stringDate)
        }

        const startHourDate: Date = new Date(stringDate + " " + (startHour.split(':').length === 3 ? startHour : startHour + ':00'))
        const endHourDate: Date | null = endHour.length === 0 ? null : new Date(stringDate + " " + (endHour.split(':').length === 3 ? endHour : endHour + ':00'))
        let restPeriodDate: Date;

        if (rest === 'automatique' && endHourDate !== null && restPeriods !== null) {
            const restPeriodAuto: RestPeriod | undefined = Journey.getRestPeriod(startHourDate, endHourDate, restPeriods);

            if (restPeriodAuto) {
                restPeriodDate = restPeriodAuto.restTime;
            } else {
                restPeriodDate = new Date(stringDate + " " + "00:00:00")
            }
        }  else if (endHourDate === null && rest === 'automatique') {
            restPeriodDate = new Date(stringDate + ' ' + "00:00:00")
            setJourneyInputs(prev => ({...prev, rest: '00:00:00'}))
        } else {
            restPeriodDate = new Date(stringDate + " " + (rest.split(':').length === 3 ? rest : rest + ':00'))
        }

        setLoading(true)
        const response = await addJourneyApi({ journeyDate: journeyDate, startHour: startHourDate, endHour: endHourDate, restPeriod: restPeriodDate, overnightRest: overnightRest }, { navigation: navigate, authentification: authentification, setAuthentification: setAuthentification })
        setLoading(false)

        // if the journey already exists
        if (response.message === "Une journée existe déjà à cette date." || response.message === "Une journée existe déjà à cette date dans votre poubelle.") {
            setTypeModalWarning('duplicateJourney')
            return
        } else if (response.message === "Le document PDF pour ce mois a déjà été généré, vous ne pouvez plus modifier ces journées.") {
            setTypeModalWarning('existingPdfFile')
            return
        }

        if (response.data && response.success) {
            const firstDayOfWeek: Date = new Date()
            firstDayOfWeek.setDate(new Date().getDate() + 1 - (firstDayOfWeek.getDay() === 0 ? 7 : new Date().getDay()))
            firstDayOfWeek.setHours(0, 0, 0, 0)
            const lastDayOfWeek: Date = new Date()
            lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6)
            lastDayOfWeek.setHours(23, 59, 59, 999)

            if (journeyDate >= firstDayOfWeek && journeyDate <= lastDayOfWeek) {
                setJourneyContext(prev => ({ ...prev, journeysWeek: { data: [...prev.journeysWeek.data, response.data!], loading: false } }))
            }

            if (journeyDate.getMonth() === new Date().getMonth() && journeyDate.getFullYear() === new Date().getFullYear()){
                setJourneyContext(prev => ({ ...prev, journeysMonth: { data: { journeys: [...prev.journeysMonth.data.journeys, response.data!], pdfFile: prev.journeysMonth.data.pdfFile }, loading: false } }))
            }

            const oneMonthAgo: Date = new Date()
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
            if (journeyDate.getMonth() === oneMonthAgo.getMonth() && journeyDate.getFullYear() === oneMonthAgo.getFullYear()){
                setJourneyContext(prev => ({ ...prev, journeysLastMonth: { data: { journeys: [...prev.journeysLastMonth.data.journeys, response.data!], pdfFile: prev.journeysLastMonth.data.pdfFile }, loading: false } }))
            }

            if (!journeyContext.pdfPeriods.data.find(pdfPeriod => pdfPeriod.month === journeyDate.getMonth() + 1 && pdfPeriod.year === journeyDate.getFullYear())) {
                setJourneyContext(prev => ({ ...prev, pdfPeriods: { data: [...prev.pdfPeriods.data, { month: journeyDate.getMonth() + 1, year: journeyDate.getFullYear() }], loading: false } }))
            }

            onCancel()
            toast.success('Journée ajoutée avec succès')
        }
    }

    const onAddReplaceJourney = async (dateText: string, startHour: string, endHour: string, rest: string, overnightRest: boolean) => {
        if (journeyErrors.date || journeyErrors.startHour || journeyErrors.endHour || journeyErrors.rest) return

        let stringDate: string = ''
        let journeyDate: Date = new Date()

        const dateParts = dateText.trim().split('/')
        if (dateParts.length === 3) {
            stringDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
            journeyDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))
        } else if (dateText.trim().split('-').length === 3) {
            stringDate = dateText.trim()
            journeyDate = new Date(stringDate)
        }

        const startHourDate: Date = new Date(stringDate + " " + (startHour.split(':').length === 3 ? startHour : startHour + ':00'))
        const endHourDate: Date | null = endHour.length === 0 ? null : new Date(stringDate + " " + (endHour.split(':').length === 3 ? endHour : endHour + ':00'))
        let restPeriodDate: Date;

        if (rest === 'automatique' && endHourDate !== null && restPeriods !== null) {
            const restPeriodAuto: RestPeriod | undefined = Journey.getRestPeriod(startHourDate, endHourDate, restPeriods);

            if (restPeriodAuto) {
                restPeriodDate = restPeriodAuto.restTime;
            } else {
                restPeriodDate = new Date(stringDate + " " + "00:00:00")
            }
        } else {
            restPeriodDate = new Date(stringDate + " " + (rest.split(':').length === 3 ? rest : rest + ':00'))
        }

        setLoading(true)
        const response = await addJourneyReplaceApi({ journeyDate: journeyDate, startHour: startHourDate, endHour: endHourDate, restPeriod: restPeriodDate, overnightRest: overnightRest }, { navigation: navigate, authentification: authentification, setAuthentification: setAuthentification })
        setLoading(false)

        if (response.data && response.success) {
            const firstDayOfWeek: Date = new Date()
            firstDayOfWeek.setDate(new Date().getDate() + 1 - (firstDayOfWeek.getDay() === 0 ? 7 : new Date().getDay()))
            firstDayOfWeek.setHours(0, 0, 0, 0)
            const lastDayOfWeek: Date = new Date()
            lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6)
            lastDayOfWeek.setHours(23, 59, 59, 999)

            if (journeyDate >= firstDayOfWeek && journeyDate <= lastDayOfWeek) {
                setJourneyContext(prev => ({ ...prev, journeysWeek: { data: [...prev.journeysWeek.data.filter(journey => moment(journey.journeyDate).format('YYYY-MM-DD') !== moment(journeyDate).format('YYYY-MM-DD')), response.data!], loading: false } }))
            }

            if (journeyDate.getMonth() === new Date().getMonth() && journeyDate.getFullYear() === new Date().getFullYear()){
                setJourneyContext(prev => ({ ...prev, journeysMonth: { data: { journeys: [...prev.journeysMonth.data.journeys.filter(journey => moment(journey.journeyDate).format('YYYY-MM-DD') !== moment(journeyDate).format('YYYY-MM-DD')), response.data!], pdfFile: prev.journeysMonth.data.pdfFile }, loading: false } }))
            }

            const oneMonthAgo: Date = new Date()
            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
            if (journeyDate.getMonth() === oneMonthAgo.getMonth() && journeyDate.getFullYear() === oneMonthAgo.getFullYear()){
                setJourneyContext(prev => ({ ...prev, journeysLastMonth: { data: { journeys: [...prev.journeysLastMonth.data.journeys.filter(journey => moment(journey.journeyDate).format('YYYY-MM-DD') !== moment(journeyDate).format('YYYY-MM-DD')), response.data!], pdfFile: prev.journeysLastMonth.data.pdfFile }, loading: false } }))
            }

            if (!journeyContext.pdfPeriods.data.find(pdfPeriod => pdfPeriod.month === journeyDate.getMonth() + 1 && pdfPeriod.year === journeyDate.getFullYear())) {
                setJourneyContext(prev => ({ ...prev, pdfPeriods: { data: [...prev.pdfPeriods.data.filter(pdf => pdf.month !== journeyDate.getMonth() + 1 && pdf.year !== journeyDate.getFullYear()), { month: journeyDate.getMonth() + 1, year: journeyDate.getFullYear() }], loading: false } }))
            }

            setTypeModalWarning(undefined)
            onCancel()
            toast.success('Journée ajoutée avec succès')
        }
    }

    const onSwitchTypeRestInput = () => {
        if (!useRestPeriodsContext) {
            const restPeriodInput: RestPeriod | undefined = restPeriods?.find(rest => moment(rest.restTime).format("HH:mm:ss") === journeyInputs.rest)
            if (restPeriodInput !== undefined) {
                setJourneyInputs(prev => ({...prev, rest: moment(restPeriodInput.restTime).format("HH:mm:ss")}))
            } else {
                setJourneyInputs(prev => ({...prev, rest: restPeriods && restPeriods.length > 0 ? 'automatique' : "00:00:00"}))
            }
        } else if (journeyInputs.rest === 'automatique') {
            setJourneyInputs(prev => ({...prev, rest: '00:00:00'}))
        }

        setUseRestPeriodsContext(prev => !prev)
    }

    useEffect(() => {
        setJourneyErrors(prev => ({...prev, date: !isDateValid(journeyInputs.date)}))

        setJourneyErrors(prev => ({...prev, startHour: !isHourValid(journeyInputs.startHour)}))
        setJourneyErrors(prev => ({...prev, endHour: !isHourValid(journeyInputs.endHour)}))
        setJourneyErrors(prev => ({...prev, rest: !isHourValid(journeyInputs.rest) && journeyInputs.rest !== 'automatique'}))
    }, [journeyInputs.date, journeyInputs.startHour, journeyInputs.endHour, journeyInputs.rest])

    return (
        <div className="fixed inset-0 bg-black/50 z-[100] duration-[2000ms]" onClick={onCancel}>
            <div className="absolute bottom-0 w-full bg-white rounded-t-lg px-4 py-2 duration-[500ms] translate-y-0 animate-[slideUp_10s_forwards]" onClick={(e: any) => {e.stopPropagation()}}>
                <div className="flex flex-row items-center justify-between">
                    <p>Ajouter une journée</p>
                    <p className="cursor-pointer underline" onClick={onCancel}>Annuler</p>
                </div>
                <div className="h-10"></div>
                {
                    typeModalWarning === 'duplicateJourney' ? (
                        <div>
                            <p>Une journée existe déjà à cette date !</p>
                            <p>Souhaitez-vous la remplacer par les informations que vous venez de saisir ?</p>
                            <div className="h-10"></div>
                            <div className="flex justify-center gap-4">
                                <SecondaryButton label="Non" onClick={() => setTypeModalWarning(undefined)} isDisabled={false} isLoading={false} />
                                <MainButton label="Oui" onClick={() => onAddReplaceJourney(journeyInputs.date, journeyInputs.startHour, journeyInputs.endHour, journeyInputs.rest, journeyInputs.overnightRest)} isDisabled={false} isLoading={loading} />
                            </div>
                        </div>
                    ) : (
                        typeModalWarning === 'existingPdfFile' ? (
                            <div>
                                <p>Le fichier PDF du mois { months[parseInt(moment(journeyInputs.date).format('MM')) - 1] } { moment(journeyInputs.date).format('YYYY') } a déjà été généré définitivement !</p>
                                <div className="h-2"></div>
                                <p>Vous ne pouvez donc plus intéragir avec les journées de ce mois.</p>
                                <div className="h-10"></div>
                                <div className="flex justify-center gap-4">
                                    <SecondaryButton label="Retour" onClick={() => setTypeModalWarning(undefined)} isDisabled={false} isLoading={false} />
                                </div>
                            </div>
                        ) : (
                            <div className="text-base flex flex-col gap-3">
                                <div className="flex flex-row items-center justify-center gap-2">
                                    <label>Date</label>
                                    <DateInput value={journeyInputs.date} isError={journeyErrors.date} inputType="interactive" onChange={(e) => setJourneyInputs(prev => ({...prev, date: e.target.value}))} />
                                </div>
                                <div className="flex flex-row items-center justify-center gap-2">
                                    <label>Heure de début</label>
                                    <TimeInput value={journeyInputs.startHour} isError={journeyErrors.startHour} inputType="interactive" onChange={(e) => setJourneyInputs(prev => ({...prev, startHour: e.target.value}))} />
                                </div>
                                <div className="flex flex-row items-center justify-center gap-4">
                                    <div className="flex flex-row items-center justify-center gap-2">
                                        <label>Heure de fin</label>
                                        <TimeInput value={journeyInputs.endHour} isError={journeyErrors.endHour} inputType="interactive" onChange={(e) => setJourneyInputs(prev => ({...prev, endHour: e.target.value}))} />
                                    </div>
                                    {
                                        journeyInputs.endHour.length !== 0 && (
                                            <Eraser color="rgb(15 118 110)" onClick={() => setJourneyInputs(prev => ({...prev, endHour: ''}))} />
                                        )
                                    }
                                </div>
                                <div className="flex flex-row items-center justify-center gap-4">
                                    <div className="flex items-center gap-2">
                                    <label>Coupure</label>
                                    {
                                        restPeriods === null || (!restPeriods.find(rp => moment(rp.restTime).format('HH:mm:ss') === journeyInputs.rest) && journeyInputs.rest !== 'automatique') || !useRestPeriodsContext ? (
                                            <TimeInput value={journeyInputs.rest} isError={journeyErrors.rest} placeholder="00:00:00" inputType="interactive" onChange={(e) => setJourneyInputs(prev => ({...prev, rest: (e.target.value.split(':').length === 2 ? e.target.value + ':00' : e.target.value)}))} />
                                        ) : (
                                            <Selector label="Coupure" options={[{ value: 'automatique', label: 'Automatique' }, ...restPeriods.map(restPeriod => ({ value: moment(restPeriod.restTime).format('HH:mm:ss'), label: moment(restPeriod.restTime).format('HH:mm:ss') }))]} isError={journeyErrors.rest} defaultValue={journeyInputs.rest} onChange={(valeur: string) => setJourneyInputs(prev => ({...prev, rest: valeur}))} />
                                        )
                                    }
                                    </div>
                                    {
                                        restPeriods !== null ? (
                                            <ArrowRightLeft color="rgb(15 118 110)" onClick={() => onSwitchTypeRestInput()} />
                                        ) : <></>
                                    }
                                </div>
                                <div className="flex flex-row items-center justify-center gap-2">
                                    <p>Découchage :&nbsp;</p>
                                    <InputSwitch checked={journeyInputs.overnightRest} onChange={(e) => setJourneyInputs(prev => ({...prev, overnightRest: !prev.overnightRest}))} />
                                </div>
                                <div className="h-4"></div>
                                <div className="text-center">
                                    <div className="w-[60%] inline-block">
                                        <MainButton label="Ajouter" isDisabled={journeyErrors.date || journeyErrors.startHour || journeyErrors.endHour || journeyErrors.rest || journeyInputs.date.length === 0 || journeyInputs.startHour.length === 0} isLoading={loading} onClick={() => onAddJourney(journeyInputs.date, journeyInputs.startHour, journeyInputs.endHour, journeyInputs.rest, journeyInputs.overnightRest)} />
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
                <div className="h-[60px]"></div>
            </div>
        </div>
    )
}

export default AddJourneyModal
import axios from "api/axios"
import errorApi, { IContexteApi } from "api/error.api"
import { AxiosResponse } from "axios"
import { Mail } from "models"

const GET_ALL_MAILS_URL: string = '/utilisateur/mails'

export interface IGetAllMailsRequest {
    page: number
}

export interface IGetAllMailsResponse {
    message: string,
    data: {
        pagination: {
            page: number,
            limite: number,
            nombre_total: number
        },
        mails: Mail[]
    }
}

export async function getMailsApi(request: IGetAllMailsRequest, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: { pagination: { page: number, limit: number, total: number }, mails: Mail[] } | null }> {
    if (!context || !context.authentification) {
        return { success: false, message: "Veuillez être connecté.", data: null }
    }
    
    try {
        const axiosResult: AxiosResponse = await axios.get(GET_ALL_MAILS_URL + `?page=${request.page}`,
            { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${context.authentification.accessToken}` } }
        )

        const response: IGetAllMailsResponse = axiosResult.data

        return { success: true, message: response.message, data: { pagination: { page: response.data.pagination.page, limit: response.data.pagination.limite, total: response.data.pagination.nombre_total }, mails: response.data.mails.map(m => Mail.fromJSON(m)) }}
    } catch (error: any) {
        return await errorApi<{ pagination: { page: number, limit: number, total: number }, mails: Mail[] }>(error, getMailsApi, request, context)
    }
}
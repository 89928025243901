import axios from "api/axios"
import errorApi, { IContexteApi } from "api/error.api"
import { AxiosResponse } from "axios"
import { Journey } from "models"
import { PdfJourneyMonthly } from "models/pdf-journey-monthly.api"

const GET_JOURNEY_MONTH_URL: string = '/utilisateur/journees/mois'

export interface IGetJourneyMonthRequest {
    month: number,
    year: number
}

export interface IGetJourneyMonthResponse {
    message: string,
    data: {
        journees: Journey[],
        fichier_pdf: PdfJourneyMonthly | null
    }
}

export async function getJourneyMonthApi(request: IGetJourneyMonthRequest, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: { journeys: Journey[], pdfFile: PdfJourneyMonthly | null } | null }> {
    if (!context || !context.authentification) {
        return { success: false, message: "Veuillez être connecté.", data: null }
    }

    try {
        const axiosResult: AxiosResponse = await axios.get(GET_JOURNEY_MONTH_URL + `?mois=${request.month}&annee=${request.year}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${context.authentification.accessToken}`
            }
        })

        const response: IGetJourneyMonthResponse = axiosResult.data

        return { success: true, message: response.message, data: { journeys: response.data.journees.map(j => Journey.fromJSON(j)), pdfFile: response.data.fichier_pdf ? PdfJourneyMonthly.fromJSON(response.data.fichier_pdf) : null }}
    } catch (error: any) {
        return await errorApi<{ journeys: Journey[], pdfFile: PdfJourneyMonthly | null }>(error, getJourneyMonthApi, request, context)
    }
}
export class PdfJourneyMonthly {
    fileName: string;
    creationDate: Date;
    month: number;
    year: number;

    public constructor(fileName: string, creationDate: Date, month: number, year: number) {
        this.fileName = fileName;
        this.creationDate = creationDate;
        this.month = month;
        this.year = year;
    }

    public static fromJSON(json: any): PdfJourneyMonthly {
        return new PdfJourneyMonthly(
            json['nom_fichier'],
            new Date(json['date_creation']),
            json['mois'],
            json['annee']
        );
    }
}
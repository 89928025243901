import axios from "api/axios"
import errorApi, { IContexteApi } from "api/error.api"
import { AxiosResponse } from "axios"

const VALIDITY_TOKEN_URL: string = '/utilisateur/token/etat'

export interface IValidityTokenRequest {
    token: string;
}

export async function validityTokenApi(request: IValidityTokenRequest, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: boolean | null }> {
    try {
        const axiosResult: AxiosResponse = await axios.get(VALIDITY_TOKEN_URL + '/' + request.token, { headers: { 'Content-Type': 'application/json' } })

        return { success: true, message: axiosResult.data.message, data: axiosResult.data.data === null }
    } catch (error: any) {
        return await errorApi<boolean>(error, validityTokenApi, request, context)
    }
}
export class Suspension {
    userId: string;
    creationDate: Date;
    endDate: Date | null;
    canAccessRestrictedArea: boolean;
    entityMessage: string;

    public constructor(userId: string, creationDate: Date, endDate: Date | null, canAccessRestrictedArea: boolean, entityMessage: string) {
        this.userId = userId;
        this.creationDate = creationDate;
        this.endDate = endDate;
        this.canAccessRestrictedArea = canAccessRestrictedArea;
        this.entityMessage = entityMessage;
    }

    public static fromJSON(json: any): Suspension {
        return new Suspension(
            json['fk_utilisateur_id'],
            new Date(json['date_ajout']),
            json['date_fin'] ? new Date(json['date_fin']) : null,
            json['peut_acceder_espace_restreint'],
            json['message_entite']
        );
    }
}
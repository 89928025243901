export class Mail {
    mailId: string;
    description: string;
    content: string | null;
    mailTypeId: number;
    emailUsed: string;
    state: 'PENDING' | 'SUCCESS' | 'FAIL';
    addDate: Date;
    sendDate: Date | null;

    public constructor (mailId: string, description: string, content: string | null, mailTypeId: number, emailUsed: string, state: 'PENDING' | 'SUCCESS' | 'FAIL', addDate: Date, sendDate: Date | null) {
        this.mailId = mailId;
        this.description = description;
        this.content = content;
        this.mailTypeId = mailTypeId;
        this.emailUsed = emailUsed;
        this.state = state;
        this.addDate = addDate;
        this.sendDate= sendDate;
    }

    public static fromJSON (json: any): Mail {
        return new Mail(
            json['pk_mail_utilisateur_id'],
            json['description'],
            json['contenu'],
            json['fk_type_mail_id'],
            json['email_utilise'],
            json['etat'] === 'ECHOUE' ? 'FAIL' : (json['etat'] === 'EN_COURS' ? 'PENDING' : 'SUCCESS'),
            new Date(json['date_ajout']),
            json['date_envoye'] ? new Date(json['date_envoye']) : null
        );
    }
}
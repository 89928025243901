import axios from "api/axios"
import errorApi, { IContexteApi } from "api/error.api"
import { Journey } from "models"

const GET_JOURNEY_URL = '/utilisateur/journees/'

export interface IGetJourneyRequest {
    journeyDate: string
}

export interface IGetJourneyResponse {
    message: string,
    data: Journey
}

export async function getJourneyApi(request: IGetJourneyRequest, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: Journey | null }> {
    if (!context || !context.authentification) {
        return { success: false, message: "Veuillez être connecté.", data: null }
    }

    try {
        const axiosResult = await axios.get(GET_JOURNEY_URL + request.journeyDate, { headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${context.authentification.accessToken}`
        } })

        const response: IGetJourneyResponse = axiosResult.data

        return { success: true, message: response.message, data: Journey.fromJSON(response.data) }
    } catch (error: any) {
        return await errorApi<Journey>(error, getJourneyApi, request, context)
    }
}
import axios from "api/axios"
import errorApi, { IContexteApi } from "api/error.api"

const RESTORE_JOURNEY_URL: string = '/utilisateur/journees/restaurer'

export interface IRestoreJourneyRequest {
    journeyDate: string
}

export interface IRestoreJourneyResponse {
    message: string,
    data: null
}

export async function restoreJourneyApi(request: IRestoreJourneyRequest, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: null }> {
    if (!context || !context.authentification) {
        return { success: false, message: "Veuillez être connecté.", data: null }
    }

    try {
        const axiosResult = await axios.patch(RESTORE_JOURNEY_URL, { date_journee: request.journeyDate }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${context.authentification.accessToken}`
            }
        })

        const response: IRestoreJourneyResponse = axiosResult.data

        return { success: true, message: response.message, data: null }
    } catch (error: any) {
        return await errorApi<null>(error, restoreJourneyApi, request, context)
    }
}
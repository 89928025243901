import axios from "api/axios"
import errorApi, { IContexteApi } from "api/error.api"
import { AxiosResponse } from "axios"
import { Journey } from "models"
import { PdfJourneyMonthly } from "models/pdf-journey-monthly.api"
import moment from "moment"

const GET_ADVANCE_SEARCH_JOURNEY_URL: string = '/utilisateur/journees/recherche-avancee'

export interface IGetAdvanceSearchJourneyRequest {
    startDate: Date,
    endDate: Date,
    page: number
}

export interface IGetAdvanceSearchJourneyResponse {
    message: string,
    data: {
        pagination: {
            page: number,
            limite: number,
            nombre_total: number
        },
        recherche: {
            journees: Journey[],
            fichier_pdf: PdfJourneyMonthly | null
        }[]
    }
}

export async function getAdvanceSearchJourneyApi(request: IGetAdvanceSearchJourneyRequest, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: { pagination: { page: number, limit: number, total: number }, research: { journeys: Journey[], pdfFile: PdfJourneyMonthly | null }[] } | null }> {
    if (!context || !context.authentification) {
        return { success: false, message: "Veuillez être connecté.", data: null }
    }

    try {
        const axiosResult: AxiosResponse = await axios.get(GET_ADVANCE_SEARCH_JOURNEY_URL + `?page=${request.page}&dateDebut=${moment(request.startDate).format('YYYY-MM-DD')}&dateFin=${moment(request.endDate).format('YYYY-MM-DD')}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${context.authentification.accessToken}`
            }
        })

        const response: IGetAdvanceSearchJourneyResponse = axiosResult.data

        return {
            success: true,
            message: response.message,
            data: {
                pagination: {
                    page: response.data.pagination.page,
                    limit: response.data.pagination.limite,
                    total: response.data.pagination.nombre_total
                },
                research: response.data.recherche.map(r => ({
                    journeys: r.journees.map(j => Journey.fromJSON(j)),
                    pdfFile: r.fichier_pdf ? PdfJourneyMonthly.fromJSON(r.fichier_pdf) : null
                }))
            }
        }
    } catch (error: any) {
        return await errorApi<{ pagination: { page: number, limit: number, total: number }, research: { journeys: Journey[], pdfFile: PdfJourneyMonthly | null }[] }>(error, getAdvanceSearchJourneyApi, request, context)
    }
}
import axios from "api/axios";
import errorApi, { IContexteApi } from "api/error.api";
import { AxiosResponse } from "axios";

const DEACTIVATE_ACCOUNT_URL: string = '/utilisateur/desactivation'

export interface IDeactivateAccountResponse {
    message: string,
    data: null
}

export async function deactivateAccountApi(request: null, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: null }> {
    if (!context || !context.authentification) {
        return { success: false, message: "Veuillez être connecté.", data: null }
    }
    
    try {
        const axiosResult: AxiosResponse = await axios.patch(DEACTIVATE_ACCOUNT_URL, {}, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${context.authentification.accessToken}` } })

        const response: IDeactivateAccountResponse = axiosResult.data

        return { success: true, message: response.message, data: null }
    } catch (error: any) {
        return await errorApi<null>(error, deactivateAccountApi, request, context)
    }
}
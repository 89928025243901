import axios from "api/axios"
import errorApi, { IContexteApi } from "api/error.api"
import { TrashJourney } from "models"

const GET_TRASH_JOURNEY_URL: string = '/utilisateur/journees/poubelle'

export interface IGetJourneyMonthResponse {
    message: string,
    data: TrashJourney[]
}

export async function getTrashJourneyApi(request: null, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: TrashJourney[] | null }> {
    if (!context || !context.authentification) {
        return { success: false, message: "Veuillez être connecté.", data: null }
    }
    
    try {
        const axiosResult = await axios.get(GET_TRASH_JOURNEY_URL, { headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${context.authentification.accessToken}`
        } })

        const response: IGetJourneyMonthResponse = axiosResult.data

        return { success: true, message: response.message, data: response.data.map(j => TrashJourney.fromJSON(j))}
    } catch (error: any) {
        return await errorApi<TrashJourney[]>(error, getTrashJourneyApi, null, context)
    }
}
import axios from "api/axios";
import errorApi, { IContexteApi } from "api/error.api";
import { AxiosResponse } from "axios";
import { LightUser } from "models";

const INSCRIPTION_URL: string = '/utilisateur/inscription'

export interface IInscriptionRequest {
    firstname: string,
    lastname: string,
    gender: string | null,
    email: string,
    password: string
}

export interface IInscriptionResponse {
    message: string,
    data: {
        pk_utilisateur_id: string,
        prenom: string,
        nom: string,
        genre: string | null,
        email: string
    }
}

export async function inscriptionApi(request: IInscriptionRequest, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: LightUser | null }> {
    try {
        const axiosResult: AxiosResponse = await axios.post(INSCRIPTION_URL, {
            prenom: request.firstname,
            nom: request.lastname,
            genre: request.gender?.length === 0 ? null : request.gender,
            email: request.email,
            mot_de_passe: request.password,
            langue: 'fr-fr'
        }, { headers: { 'Content-Type': 'application/json' } })

        const response: IInscriptionResponse = axiosResult.data

        if (response.data) {
            return { success: true, message: response.message, data: LightUser.fromJSON(response.data) }
        } else {
            return { success: false, message: response.message, data: null }
        }
    } catch (error: any) {
        return await errorApi<LightUser>(error, inscriptionApi, request, context)
    }
}
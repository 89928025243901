import axios from "api/axios"
import errorApi, { IContexteApi } from "api/error.api"
import { AxiosResponse } from "axios"
import { Journey } from "models"

const GET_JOURNEY_WEEK_URL: string = '/utilisateur/journees/semaine'

export interface IGetJourneyWeekResponse {
    message: string,
    data: Journey[]
}

export async function getJourneyWeekApi(context: IContexteApi | null): Promise<{ success: boolean, message: string, data: Journey[] | null}> {
    if (!context || !context.authentification) {
        return { success: false, message: "Veuillez être connecté.", data: null }
    }

    try {
        const axiosResult: AxiosResponse = await axios.get(GET_JOURNEY_WEEK_URL, { headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${context.authentification.accessToken}`
        } })

        const response: IGetJourneyWeekResponse = axiosResult.data

        return { success: true, message: response.message, data: response.data.map(j => Journey.fromJSON(j))}
    } catch (error: any) {
        return await errorApi<Journey[]>(error, getJourneyWeekApi, null, context)
    }
}
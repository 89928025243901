import { getJourneyApi } from "api/journey/get-journey.api";
import SuspensionInformation from "components/informations/suspension.component";
import { CenterPageLoader } from "components/loaders";
import TemplateJourney from "components/templates/template-journey.component";
import useAuthentification from "hooks/useAuthentification.hook";
import { Journey } from "models";
import { NavBarSection } from "models/enums/nav-bar-section.enum";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { useEffect, useState } from "react";
import { Location, NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowRightLeft, Eraser, Trash2 } from "lucide-react";
import { MainButton, SecondaryButton } from "components/buttons";
import TimeInput from "components/inputs/time.component";
import { IUpdateJourneyRequest, updateJourneyApi } from "api/journey/update-journey.api";
import { toast } from "sonner";
import useJourney from "hooks/useJourney.hook";
import CenterModal from "components/modals/center-modal.component";
import { deleteJourneyApi } from "api/journey/delete-journey.api";
import { RestPeriod } from "models/rest-period.model";
import { Selector } from "components/inputs";
import { getPeriodPdfMonthApi } from "api/journey/get-period-pdf-month.api";

const UpdateJourneyPage: React.FC = () => {
    const { authentification, setAuthentification } = useAuthentification();
    const { journeyContext, setJourneyContext } = useJourney();

    const location: Location = useLocation();
    const locationState: any = location.state;

    const navigate: NavigateFunction = useNavigate();

    const { dateJourney } = useParams<{ dateJourney: string }>();

    const restPeriods: RestPeriod[] | null = journeyContext.restPeriods.data
    const dateString: string = dateJourney ? dateJourney : moment().format('YYYY-MM-DD');

    const [loadingGetJourney, setLoadingGetJourney] = useState<boolean>(false);
    const [errorParams, setErrorParams] = useState<boolean>(false);
    const [journey, setJourney] = useState<Journey | null>(null);

    const [loadingPatchJourney, setLoadingPatchJourney] = useState<boolean>(false);
    const [canUpdateJourney, setCanUpdateJourney] = useState<boolean>(false);

    const [deleteJourney, setDeleteJourney] = useState<boolean>(false);
    const [deleteJourneyLoading, setDeleteJourneyLoading] = useState<boolean>(false);

    const [startHour, setStartHour] = useState<string>('');
    const [endHour, setEndHour] = useState<string>('');
    const [restPeriod, setRestPeriod] = useState<string>('');
    const [overnightRest, setOvernightRest] = useState<boolean>(false);

    const [useRestPeriodsContext, setUseRestPeriodsContext] = useState<boolean>(false);

    const isCorrectDate = (date: string): boolean => dateJourney ? /^20[0-9]{2}-(((01|03|05|07|08|10|12)-(([0-2][1-9])|3[01]))|((04|06|09|11)-(([0-2][1-9])|30))|(02-(([0-1][1-9])|2[0-9])))$/.test(date) : false;

    const calculCanUpdateJourney = (): boolean => {
        if (!journey) return false

        return startHour !== moment(journey.startHour).format('HH:mm:ss') ||
            endHour !== (journey.endHour ? moment(journey.endHour).format('HH:mm:ss') : '') ||
            restPeriod === 'automatique' ||
            restPeriod !== moment(journey.restPeriod).format('HH:mm:ss') ||
            overnightRest !== journey.overnightRest
    }

    const fetchJourney = async (dateJourney: string) => {
        setLoadingGetJourney(true);

        const response = await getJourneyApi({ journeyDate: dateJourney }, { navigation: navigate, authentification: authentification, setAuthentification: setAuthentification })

        if (response.success && response.data !== null) {
            setJourney(response.data)

            setStartHour(moment(response.data.startHour).format('HH:mm:ss'));
            setEndHour(response.data.endHour ? moment(response.data.endHour).format('HH:mm:ss') : '');
            setRestPeriod(moment(response.data.restPeriod).format('HH:mm:ss'));
            setOvernightRest(response.data.overnightRest);
        }

        setLoadingGetJourney(false);
    }

    const onUpdateJourney = async () => {
        if (authentification && authentification.payload.desactivation) {
            toast.error("Votre compte est désactivé. Vous ne pouvez pas modifier de journées.")
            return
        }

        if (!journey) return

        setLoadingPatchJourney(true);

        const request: IUpdateJourneyRequest = {
            journeyDate: journey.journeyDate,
            startHour: new Date(moment(journey.journeyDate).format('YYYY-MM-DD') + ' ' + startHour),
            endHour: endHour ? new Date(moment(journey.journeyDate).format('YYYY-MM-DD') + ' ' + endHour) : null,
            restPeriod: new Date(moment(journey.journeyDate).format('YYYY-MM-DD') + ' ' + restPeriod),
            overnightRest: overnightRest
        }


        if (restPeriods && endHour !== '' && restPeriod === 'automatique') {
            const restPeriodAuto: RestPeriod | undefined = Journey.getRestPeriod(new Date(dateString + ' ' + startHour), new Date(dateString + ' ' + endHour), restPeriods);

            if (restPeriodAuto) {
                request.restPeriod = restPeriodAuto.restTime
            }
        } else if (endHour === '' && restPeriod === 'automatique') {
            request.restPeriod = new Date(moment(journey.journeyDate).format('YYYY-MM-DD') + ' ' + '00:00:00')
            setRestPeriod('00:00:00')
        }

        const response = await updateJourneyApi(request, { navigation: navigate, authentification: authentification, setAuthentification: setAuthentification })
        if (response.success && response.data !== null) {
            setJourney(response.data)
            setRestPeriod(moment(response.data.restPeriod).format('HH:mm:ss'))

            const indexJourneyMonth = journeyContext.journeysMonth.data.journeys.findIndex(j => j.journeyDate.getDate() === journey.journeyDate.getDate())
            if (indexJourneyMonth !== -1) {
                const newJourneys = [...journeyContext.journeysMonth.data.journeys]
                newJourneys[indexJourneyMonth] = response.data
                setJourneyContext(prev => ({...prev, journeysMonth: { data: { journeys: newJourneys, pdfFile: prev.journeysMonth.data.pdfFile }, loading: false } }) )
            } 

            const indexJourneyLastMonth = journeyContext.journeysLastMonth.data.journeys.findIndex(j => j.journeyDate.getDate() === journey.journeyDate.getDate())
            if (indexJourneyLastMonth !== -1) {
                const newJourneys = [...journeyContext.journeysLastMonth.data.journeys]
                newJourneys[indexJourneyLastMonth] = response.data
                setJourneyContext(prev => ({...prev, journeysLastMonth: { data: { journeys: newJourneys, pdfFile: prev.journeysLastMonth.data.pdfFile }, loading: false } }) )
            }

            const indexJourneyWeek = journeyContext.journeysWeek.data.findIndex(j => j.journeyDate.getDate() === journey.journeyDate.getDate())
            if (indexJourneyWeek !== -1) {
                const newJourneys = [...journeyContext.journeysWeek.data]
                newJourneys[indexJourneyWeek] = response.data
                setJourneyContext(prev => ({...prev, journeysWeek: { data: newJourneys, loading: false } }) )
            }

            toast.success("Journée modifiée avec succès.")
        }

        setLoadingPatchJourney(false);
    }

    const onDeleteJourney = async () => {
        if (authentification && authentification.payload.desactivation) {
            toast.error("Votre compte est désactivé. Vous ne pouvez pas supprimer une journée.")
            return
        }

        if (!journey) return
        if (!deleteJourney) return setDeleteJourney(true)

        setDeleteJourneyLoading(true);

        const response = await deleteJourneyApi({ journeyDate: moment(journey.journeyDate).format("YYYY-MM-DD") }, { navigation: navigate, authentification: authentification, setAuthentification: setAuthentification })

        if (response.success) {
            if (journeyContext.journeysMonth.data.journeys.find(j => moment(j.journeyDate).format("YYYY-MM-DD") === moment(journey.journeyDate).format("YYYY-MM-DD"))) {
                const newJourneys = [...journeyContext.journeysMonth.data.journeys.filter(j => moment(j.journeyDate).format("YYYY-MM-DD") !== moment(journey.journeyDate).format("YYYY-MM-DD"))]
                setJourneyContext(prev => ({...prev, journeysMonth: { data: { journeys: newJourneys, pdfFile: prev.journeysMonth.data.pdfFile }, loading: false } }) )
            }

            if (journeyContext.journeysLastMonth.data.journeys.find(j => moment(j.journeyDate).format("YYYY-MM-DD") === moment(journey.journeyDate).format("YYYY-MM-DD"))) {
                const newJourneys = [...journeyContext.journeysLastMonth.data.journeys.filter(j => moment(j.journeyDate).format("YYYY-MM-DD") !== moment(journey.journeyDate).format("YYYY-MM-DD"))]
                setJourneyContext(prev => ({...prev, journeysLastMonth: { data: { journeys: newJourneys, pdfFile: prev.journeysLastMonth.data.pdfFile }, loading: false } }) )
            }

            if (journeyContext.journeysWeek.data.find(j => moment(j.journeyDate).format("YYYY-MM-DD") === moment(journey.journeyDate).format("YYYY-MM-DD"))) {
                const newJourneys = [...journeyContext.journeysWeek.data.filter(j => moment(j.journeyDate).format("YYYY-MM-DD") !== moment(journey.journeyDate).format("YYYY-MM-DD"))]
                setJourneyContext(prev => ({...prev, journeysWeek: { data: newJourneys, loading: false } }) )
            }

            updatePdfPeriods()

            toast.success("Journée supprimée avec succès.")
            navigate('/dashboard/journees')
        }

        setDeleteJourneyLoading(false);
    }

    const updatePdfPeriods = async () => {
        setJourneyContext(prev => ({ ...prev, pdfPeriods: { ...prev.pdfPeriods, loading: true } }))

        const response = await getPeriodPdfMonthApi({ navigation: navigate, authentification: authentification, setAuthentification: setAuthentification });

        if (response.success && response.data !== null)
            setJourneyContext(prev => ({ ...prev, pdfPeriods: { ...prev.pdfPeriods, data: response.data! } }))

        setJourneyContext(prev => ({ ...prev, pdfPeriods: { ...prev.pdfPeriods, loading: false } }))
    }

    const onSwitchTypeRestInput = () => {
        if (!useRestPeriodsContext) {
            const restPeriodInput: RestPeriod | undefined = restPeriods?.find(rest => moment(rest.restTime).format("HH:mm:ss") === restPeriod)
            if (restPeriodInput !== undefined) {
                setRestPeriod(moment(restPeriodInput.restTime).format("HH:mm:ss"))
            } else {
                setRestPeriod(restPeriods && restPeriods.length > 0 ? moment(restPeriods[0].restTime).format("HH:mm:ss") : "00:00:00")
            }
        } else if (restPeriod === 'automatique') {
            setRestPeriod('00:00:00')
        }

        setUseRestPeriodsContext(prev => !prev)
    }

    useEffect(() => {
        if (locationState) {
            setJourney(locationState.journey)
            setStartHour(moment(locationState.journey.startHour).format('HH:mm:ss'));
            setEndHour(locationState.journey.endHour ? moment(locationState.journey.endHour).format('HH:mm:ss') : '');
            setRestPeriod(moment(locationState.journey.restPeriod).format('HH:mm:ss'));
            setOvernightRest(locationState.journey.overnightRest);
        } else if (dateJourney === undefined || !isCorrectDate(dateJourney)) {
            setErrorParams(true);
            setLoadingGetJourney(false);
            return
        } else {
            fetchJourney(dateJourney);
        }
    }, [dateJourney])

    useEffect(() => {
        setUseRestPeriodsContext(restPeriods !== null && journey !== null && restPeriods.find(rp => moment(rp.restTime).format('HH:mm:ss') === moment(journey.restPeriod).format('HH:mm:ss')) !== undefined)
    }, [restPeriods, journey])

    useEffect(() => {
        setCanUpdateJourney(calculCanUpdateJourney())
    }, [journey, startHour, endHour, restPeriod, overnightRest])

    return (
        <TemplateJourney title="Modifier une journée" selectedSection={NavBarSection.JOURNEYS} onClickReturn={() => navigate('/dashboard/journees')} updateMetaForOverlay={false} >
            {
                authentification && authentification.payload.suspension ? (
                    <SuspensionInformation contactEmail={authentification.payload.suspension.contactEmail} />
                ) : (
                    loadingGetJourney ? <CenterPageLoader content="Chargement de la journée" /> : (
                        errorParams ? (
                            <div className='absolute right-1/2 top-1/2 w-full -translate-y-1/2 translate-x-1/2'>
                                <div className="px-4 text-center">
                                    <p>Paramètres incorrects...</p>
                                </div>
                            </div>
                        ) :
                        !journey ? (
                            <div className='absolute right-1/2 top-1/2 w-full -translate-y-1/2 translate-x-1/2'>
                                <div className="px-4 text-center">
                                    <p>Aucune journée n'a été trouvée.</p>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col gap-6 px-4">
                                <div className="flex gap-2 items-center justify-end text-red-600 py-2" onClick={() => onDeleteJourney()}>
                                    <p>Supprimer</p>
                                    <Trash2 size={18} />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <div className="flex">
                                        <p>Date :&nbsp;</p>
                                        <p className="text-slate-600">{ moment(journey.journeyDate).format("DD/MM/YYYY") }</p>
                                    </div>
                                    <div className="flex items-center">
                                        <p>Heure de début :&nbsp;</p>
                                        <TimeInput value={startHour} isError={false} inputType="interactive" onChange={(e) => setStartHour(e.target.value)} />
                                    </div>
                                    <div className="flex flex-row items-center gap-4">
                                        <div className="flex items-center">
                                            <p>Heure de fin :&nbsp;</p>
                                            <TimeInput value={endHour} isError={false} inputType="interactive" onChange={(e) => setEndHour(e.target.value)} />
                                        </div>
                                        {
                                            journey && endHour !== '' && <Eraser color="rgb(15 118 110)" onClick={() => setEndHour('')} />
                                        }
                                    </div>
                                    <div className="flex flex-row items-center gap-4">
                                        <div className="flex items-center">
                                            <p>Coupure :&nbsp;</p>
                                            {
                                                restPeriods === null || !restPeriods.find(rp => moment(rp.restTime).format('HH:mm:ss') === restPeriod) && restPeriod !== 'automatique' || !useRestPeriodsContext ? (
                                                    <TimeInput value={restPeriod} isError={false} inputType="interactive" onChange={(e) => setRestPeriod((e.target.value.split(':').length == 2 ? e.target.value + ':00' : e.target.value))} />
                                                ) : (
                                                    <Selector label="Coupure" options={[{ value: 'automatique', label: 'Automatique' }, ...restPeriods.map(restPeriod => ({ value: moment(restPeriod.restTime).format('HH:mm:ss'), label: moment(restPeriod.restTime).format('HH:mm:ss') }))]} isError={false} defaultValue={restPeriod} onChange={(valeur: string) => setRestPeriod(valeur)} />
                                                )
                                            }
                                        </div>
                                        {
                                            restPeriods !== null ? (
                                                <ArrowRightLeft color="rgb(15 118 110)" onClick={() => onSwitchTypeRestInput()} />
                                            ) : <></>
                                        }
                                    </div>
                                    
                                    <div className="flex items-center">
                                        <p>Découchage :&nbsp;</p>
                                        <InputSwitch checked={overnightRest} onChange={(e) => setOvernightRest(prev => !prev)} />
                                    </div>
                                </div>
                                <div>
                                    <MainButton label="Enregistrer" isDisabled={!canUpdateJourney} isLoading={loadingPatchJourney} onClick={() => onUpdateJourney()}/>
                                </div>
                                {
                                    deleteJourney && <CenterModal onCancel={() => setDeleteJourney(false)}>
                                        <div className="flex flex-col gap-4">
                                            <p>Êtes-vous sûr de vouloir supprimer cette journée ?</p>
                                            <div className="flex justify-around gap-4">
                                                <SecondaryButton label="Non" onClick={() => setDeleteJourney(false)} isDisabled={false} isLoading={false} />
                                                <MainButton label="Oui" onClick={() => onDeleteJourney()} isDisabled={false} isLoading={deleteJourneyLoading} />
                                            </div>
                                        </div>
                                    </CenterModal>
                                }
                            </div>
                        )
                    )
                )
            }
        </TemplateJourney>
    )
}

export default UpdateJourneyPage
import axios from "api/axios"
import errorApi, { IContexteApi } from "api/error.api"
import { Journey } from "models"
import moment from "moment"

const ADD_JOURNEY_REPLACE_URL: string = '/utilisateur/journees/ajouter/doublon'

export interface IAddJourneyReplaceRequest {
    journeyDate: Date,
    startHour: Date,
    endHour: Date | null,
    restPeriod: Date,
    overnightRest: boolean
}

export interface IAddJourneyReplaceResponse {
    message: string,
    data: Journey
}

export async function addJourneyReplaceApi(request: IAddJourneyReplaceRequest, context: IContexteApi | null): Promise<{ success: boolean,  message: string, data: Journey | null }> {
    if (!context || !context.authentification) {
        return { success: false, message: "Veuillez être connecté.", data: null }
    }

    try {
        const axiosResult = await axios.post(ADD_JOURNEY_REPLACE_URL, {
            date_journee: moment(request.journeyDate).format("YYYY-MM-DD"),
            heure_debut: moment(request.startHour).format("HH:mm:ss"),
            heure_fin: request.endHour ? moment(request.endHour).format("HH:mm:ss") : null,
            coupure: moment(request.restPeriod).format("HH:mm:ss"),
            decouchage: request.overnightRest
        }, { headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${context.authentification.accessToken}`
        } })

        const response: IAddJourneyReplaceResponse = axiosResult.data

        return { success: true, message: response.message, data: Journey.fromJSON(response.data)}
    } catch (error: any) {
        return await errorApi<Journey>(error, addJourneyReplaceApi, request, context)
    }
}
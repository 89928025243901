import moment from "moment";

export class RestPeriod {
    startInterval: Date;
    endInterval: Date;
    restTime: Date;

    public constructor(startInterval: Date, endInterval: Date, restTime: Date) {
        this.startInterval = startInterval;
        this.endInterval = endInterval;
        this.restTime = restTime;
    }

    public static fromJSON(json: any): RestPeriod {
        const journeyString: string = moment(new Date()).format("YYYY-MM-DD");
        
        return new RestPeriod(
            new Date(journeyString + " " + json['borne_debut']),
            new Date(journeyString + " " + json['borne_fin']),
            new Date(journeyString + " " + json['temps_coupure'])
        );
    }
}
import { Building, CalendarSearch, FileText, Truck, User } from "lucide-react";
import { ReactNode } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { NavBarSection } from "models/enums/nav-bar-section.enum";

interface INavigationBarProps {
    selectedSection: NavBarSection
}

const NavigationBar: React.FC<INavigationBarProps> = ({ selectedSection }) => {
    const navigate: NavigateFunction = useNavigate()

    const sections: { name: string, icon: ReactNode, type: NavBarSection, redirection: string }[] = [
        {
            name: 'Accueil',
            icon: <Truck size={'30px'} strokeWidth={1} />,
            type: NavBarSection.HOME,
            redirection: '/dashboard/semaine'
        },
        {
            name: 'Journées',
            icon: <CalendarSearch size={'30px'} strokeWidth={1} />,
            type: NavBarSection.JOURNEYS,
            redirection: '/dashboard/journees'
        },
        {
            name: 'Documents',
            icon: <FileText size={'30px'} strokeWidth={1} />,
            type: NavBarSection.PDF,
            redirection: '/dashboard/documents'
        },
        {
            name: 'Entreprise',
            icon: <Building size={'30px'} strokeWidth={1} />,
            type: NavBarSection.COMPANY,
            redirection: '/dashboard/entreprise'
        },
        {
            name: 'Compte',
            icon: <User size={'30px'} strokeWidth={1} />,
            type: NavBarSection.PROFILE,
            redirection: '/dashboard/compte'
        }
    ]

    return (
        <div className="fixed bottom-0 z-50 w-full px-4 py-2 flex justify-between bg-white border border-[#DADADA]" style={window.matchMedia('(display-mode: standalone)').matches ? { paddingBottom: 'calc(24px + env(safe-area-inset-bottom))' } : {}}>
            {
                sections.map((selction, index: number) => (
                    <div key={index} className={`flex flex-col items-center cursor-pointer ${selectedSection === selction.type ? 'text-[#232B35]' : 'text-gray-400'}`} onClick={() => navigate(selction.redirection)}>
                        {selction.icon}
                        <p className="text-sm">{selction.name}</p>
                    </div>
                ))
            }
        </div>
    )
}

export default NavigationBar;
export class MailType {
    mailTypeId: number;
    label: string;
    description: string;
    canBeModified: boolean = false;

    public constructor (mailTypeId: number, label: string, description: string, canBeModified: boolean) {
        this.mailTypeId = mailTypeId;
        this.label = label;
        this.description = description;
        this.canBeModified = canBeModified;
    }

    public static fromJSON (json: any): MailType {
        return new MailType(
            json['pk_type_mail_id'],
            json['label'],
            json['description'],
            json['est_modifiable']
        );
    }
}
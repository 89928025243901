import axios from "api/axios"
import errorApi, { IContexteApi } from "api/error.api"
import { Journey } from "models"
import moment from "moment"

const UPDATE_JOURNEY_URL = '/utilisateur/journees/modifier'

export interface IUpdateJourneyRequest {
    journeyDate: Date,
    startHour: Date,
    endHour: Date | null,
    restPeriod: Date,
    overnightRest: boolean
}

export interface IUpdateJourneyResponse {
    message: string,
    data: Journey
}

export async function updateJourneyApi(request: IUpdateJourneyRequest, context: IContexteApi | null): Promise<{ success: boolean, message: string, data: Journey | null }> {
    if (!context || !context.authentification) {
        return { success: false, message: "Veuillez être connecté.", data: null }
    }

    try {
        const axiosResult = await axios.patch(UPDATE_JOURNEY_URL, {
            date_journee: moment(request.journeyDate).format("YYYY-MM-DD"),
            heure_debut: moment(request.startHour).format("HH:mm:ss"),
            heure_fin: request.endHour ? moment(request.endHour).format("HH:mm:ss") : null,
            coupure: moment(request.restPeriod).format("HH:mm:ss"),
            decouchage: request.overnightRest
        }, { headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${context.authentification.accessToken}`
        } })

        const response: IUpdateJourneyResponse = axiosResult.data

        return { success: true, message: response.message, data: Journey.fromJSON(response.data) }
    } catch (error: any) {
        return await errorApi<Journey>(error, updateJourneyApi, request, context)
    }
}